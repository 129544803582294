import { supabase } from '../queries/Queries'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkUserProfile, handleUsernameSubmit } from '../queries/Queries';

const AuthCallback = () => {
  const [username, setUsername] = useState('');
  const [showUsernamePrompt, setShowUsernamePrompt] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_IN' && session) {
        const profile = await checkUserProfile(session.user.id);
        if (!profile || !profile.username) {
          setShowUsernamePrompt(true);
        } else {
          navigate('/');
        }
      }
    });
  }, [navigate]);

  const onUsernameSubmit = async (e) => {
    e.preventDefault();
    const session = await supabase.auth.getSession();
    if (session && session.data.session) {
      const success = await handleUsernameSubmit(session.data.session.user.id, username);
      if (success) {
        navigate('/');
      } else {
        // Handle error (e.g., show error message to user)
      }
    }
  };

  if (showUsernamePrompt) {
    return (
      <div>
        <h2>Almost there! Please choose a username:</h2>
        <form onSubmit={onUsernameSubmit}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter username"
            required
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }

  return <div>Completing authentication, please wait...</div>;
};

export default AuthCallback;