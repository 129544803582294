export class TtsHandler {
    constructor(apiKey) {
      this.apiKey = apiKey;
      this.audioQueue = [];
      this.isPlaying = false;
      this.currentAudio = null;
      this.currentSegmentIndex = 0;
      this.isProcessing = false;
      this.playbackSpeed = 1;
    }
  
    segmentTextBySentence(text) {
      const sentences = text.match(/[^.!?]+[.!?]/g)?.map(sentence => sentence.trim()) || [text];
      const combinedSentences = [];
      let currentChunk = '';
      
      for (const sentence of sentences) {
        if ((currentChunk + sentence).length < 100) {
          currentChunk += (currentChunk ? ' ' : '') + sentence;
        } else {
          if (currentChunk) combinedSentences.push(currentChunk);
          currentChunk = sentence;
        }
      }
      if (currentChunk) combinedSentences.push(currentChunk);
      
      return combinedSentences;
    }
  
    async synthesizeSegment(text) {
      try {
        await new Promise(resolve => setTimeout(resolve, 250));
  
        const response = await fetch('https://api.deepgram.com/v1/speak?model=aura-helios-en', {
          method: 'POST',
          headers: {
            'Authorization': `Token ${this.apiKey}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ text })
        });
  
        if (!response.ok) {
          const error = await response.text();
          throw new Error(`TTS API error: ${error}`);
        }
        
        const audioBlob = await response.blob();
        return URL.createObjectURL(audioBlob);
      } catch (error) {
        console.error('Error synthesizing segment:', error);
        throw error;
      }
    }
  
    async queueText(text, speed = 1) {
      if (this.isProcessing) {
        return false;
      }
  
      try {
        this.isProcessing = true;
        this.stop();
        
        this.audioQueue = [];
        this.currentSegmentIndex = 0;
        this.playbackSpeed = speed;
        
        const segments = this.segmentTextBySentence(text);
        
        // Get first segment
        const firstSegment = segments[0];
        if (!firstSegment) return false;
  
        try {
          const firstAudioUrl = await this.synthesizeSegment(firstSegment);
          this.audioQueue.push(firstAudioUrl);
          this.isPlaying = true;
          this.playNext();
  
          // Process remaining segments
          for (let i = 1; i < segments.length; i++) {
            const audioUrl = await this.synthesizeSegment(segments[i]);
            this.audioQueue.push(audioUrl);
          }
        } catch (error) {
          console.error('Error processing segments:', error);
          return false;
        }
  
        return true;
      } catch (error) {
        console.error('Error in TTS:', error);
        return false;
      } finally {
        this.isProcessing = false;
      }
    }
  
    playNext() {
      if (!this.isPlaying || !this.audioQueue.length || this.currentSegmentIndex >= this.audioQueue.length) {
        this.stop();
        return;
      }
  
      try {
        const currentUrl = this.audioQueue[this.currentSegmentIndex];
        if (!currentUrl) {
          this.stop();
          return;
        }
  
        this.currentAudio = new Audio(currentUrl);
        this.currentAudio.playbackRate = this.playbackSpeed;
  
        this.currentAudio.onended = () => {
          URL.revokeObjectURL(currentUrl);
          this.currentSegmentIndex++;
          this.playNext();
        };
  
        this.currentAudio.onerror = () => {
          console.error('Error playing audio segment');
          URL.revokeObjectURL(currentUrl);
          this.currentSegmentIndex++;
          this.playNext();
        };
  
        this.currentAudio.play().catch(error => {
          console.error('Playback error:', error);
          this.stop();
        });
      } catch (error) {
        console.error('Error in playNext:', error);
        this.stop();
      }
    }
  
    setPlaybackSpeed(speed) {
      this.playbackSpeed = speed;
      if (this.currentAudio) {
        this.currentAudio.playbackRate = speed;
      }
    }
  
    togglePlayPause() {
      if (!this.currentAudio) return;
      
      try {
        if (this.isPlaying) {
          this.currentAudio.pause();
        } else {
          this.currentAudio.play().catch(console.error);
        }
        this.isPlaying = !this.isPlaying;
      } catch (error) {
        console.error('Error toggling playback:', error);
      }
    }
  
    stop() {
      this.isPlaying = false;
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio = null;
      }
      // Clean up URLs
      this.audioQueue.forEach(url => {
        try {
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error revoking URL:', error);
        }
      });
      this.audioQueue = [];
      this.currentSegmentIndex = 0;
    }
  }