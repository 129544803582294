import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import TopMenuBar from "../components/TopMenuBar";
import { getCurrentUserAndProfile, handleSignOut } from "../queries/Queries.js";
import { Bot, CheckCircle, Landmark } from 'lucide-react';
import styles from "../styles/LandingPage.module.css";
import DiscordPopup from '../components/DiscordPopup';


const LandingPage = () => {
  const navigate = useNavigate();
  const [userAndProfile, setUserAndProfile] = useState(null);

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  const handleSignOutClick = async () => {
    const success = await handleSignOut();
    if (success) {
      setUserAndProfile(null);
      navigate('/');
    }
  };

  const handleSignUpSubmit = () => {
    navigate("/signup");
  };

  const handlePlay = () => {
    navigate("/ai");
  };

  return (
    <div className={styles.landingPage}>
      <TopMenuBar userAndProfile={userAndProfile} onSignOut={handleSignOutClick} />

      <DiscordPopup />
      <main className={styles.mainContent}>
        {/* Hero Section */}
        <section className={styles.heroSection}>
          <div className={styles.heroContent}>
            <h1>chess.com, but for debate</h1>
            <p className={styles.subtitle}>Practice, improve, and compete in competitive debate</p>
            <div className={styles.buttonGroup}>
              {!userAndProfile && (
                <button className={styles.signUpButton} onClick={handleSignUpSubmit}>
                  sign up
                </button>
              )}
              <button 
                className={`${styles.playButton} ${userAndProfile ? styles.greenButton : ''}`} 
                onClick={handlePlay}
              >
                {userAndProfile ? "start debating" : "try it out"}
              </button>
            </div>
          </div>

          {/* Features Grid */}
          <div className={styles.featuresGrid}>
            <div className={styles.featureCard} onClick={() => navigate('/bpai')}>
              <div className={styles.featureIcon}>
                <Landmark size={24} />
                <div className={styles.newBadge}>New</div>
              </div>
              <h3>British Parliamentary AI</h3>
              <p>Verse the AI in a mini BP debate on any motion</p>
            </div>

            <div className={styles.featureCard} onClick={() => navigate('/ai')}>
              <div className={styles.featureIcon}>
                <Bot size={24} />
              </div>
              <h3>Debate vs AI</h3>
              <p>Challenge AI personas in various debate formats</p>
            </div>

            <div className={styles.featureCard} onClick={() => navigate('/fact-check')}>
              <div className={styles.featureIcon}>
                <CheckCircle size={24} />
              </div>
              <h3>AI Fact Checking</h3>
              <p>Verify claims instantly with AI-powered sources</p>
            </div>
          </div>
        </section>
      </main>

      <footer className={styles.footer}>
        <Link to="/terms-of-use" className={styles.footerLink}>terms of use</Link>
        <span className={styles.footerSeparator}>|</span>
        <Link to="/privacy-policy" className={styles.footerLink}>privacy policy</Link>
      </footer>
    </div>
  );
};

export default LandingPage;