import React, { useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
  Menu, 
  Bot, 
  CheckCircle, 
  Users,
  Settings,
  History,
  LogOut,
  Heart,
  LogIn,
  Landmark,
  Share2,
  MessageCircle,
  Network,
  Handshake,
  Swords
} from 'lucide-react';
import styles from '../styles/TopMenuBar.module.css';
import coliseumLogo from '../images/coliseum_logo.png';

const TopMenuBar = ({ userAndProfile, onSignOut }) => {
  const navigate = useNavigate();
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false);
  const navDrawerRef = useRef(null);

  const handleDiscordClick = () => {
    window.open('https://discord.gg/QWJz2MN5MV', '_blank');
  };

  const handleDonateClick = () => {
    window.open('https://donate.stripe.com/8wMeVNaex80db2o3cc', '_blank');
  };

  const toggleNavDrawer = () => {
    setIsNavDrawerOpen(!isNavDrawerOpen);
  };

  const handleLogout = () => {
    navigate('/');
    setIsNavDrawerOpen(false);
    onSignOut();
  };

  const handleSocialClick = () => {
    navigate('/profile?tab=friends');
    setIsNavDrawerOpen(false);
  };

  return (
    <>
      <div className={styles.topMenuBar}>
        <div className={styles.leftSection}>
          <div 
            className={`${styles.hamburgerButton} ${isNavDrawerOpen ? styles.active : ''}`} 
            onClick={toggleNavDrawer}
          >
            <Menu size={24} />
          </div>
        </div>
        <Link to="/" className={styles.logoLink}>
          <div className={styles.logoContainer}>
            <div className={styles.logo}>debate<span style={{color: "#4CAF50"}}>arena</span></div>
            <img src={coliseumLogo} alt="Coliseum Logo" className={styles.coliseumLogo} />
          </div>
        </Link>
        <div className={styles.rightSection}>
          <div className={`${styles.discordLink} ${styles.desktopOnly}`} onClick={handleDiscordClick}>
            <svg className={styles.discordIcon} width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z"/>
            </svg>
            Join our Discord
          </div>
        </div>
      </div>

      {/* Navigation Drawer */}
      {isNavDrawerOpen && (
        <div className={styles.navDrawer} ref={navDrawerRef}>
          <div className={styles.navContent}>
            <div className={styles.navItems}>
              <div className={styles.menuItem} onClick={() => { navigate('/ai'); }}>
                <Bot size={20} />
                <span>Debate vs AI</span>
              </div>
              <div className={styles.menuItem} onClick={() => { navigate('/bpai'); }}>
                <Landmark size={20} />
                <span>BP vs AI</span>
              </div>
              <div className={styles.menuItem} onClick={() => { navigate('/fact-check'); }}>
                <CheckCircle size={20} />
                <span>AI Fact Checking</span>
              </div>
              <div className={styles.menuItem} onClick={() => { navigate('/rooms'); }}>
                <Swords size={20} />
                <span>Play Online</span>
                <span className={styles.comingSoonBadge}>new!</span>
              </div>
              {userAndProfile?.user ? (<div className={styles.menuItem} onClick={handleSocialClick}>
                <Users size={20} />
                <span>Social</span>
              </div>) : (<></>) }
              <div className={`${styles.menuItem} ${styles.mobileOnly}`} onClick={handleDiscordClick}>
                <svg className={styles.discordIcon} width="20" height="20" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z"/>
                </svg>
                <span>Join our Discord</span>
              </div>
              <div className={styles.donateButton} onClick={handleDonateClick}>
                <Heart size={14} />
                <span>Support Us</span>
              </div>
            </div>

            {/* Profile Card Section - Conditional Render */}
            {userAndProfile?.user ? (
              <div className={styles.profileCard}>
                <div className={styles.profileInfo}>
                  <div className={styles.profileImageContainer}>
                    <img 
                      src="/api/placeholder/40/40" 
                      alt="Profile" 
                      className={styles.profileImage} 
                    />
                  </div>
                  <div className={styles.profileName}>
                    {userAndProfile.profile?.username || userAndProfile.user.email}
                  </div>
                </div>
                
                <div className={styles.profileActions}>
                  <button 
                    className={styles.profileButton} 
                    onClick={() => navigate('/profile')}
                  >
                    <History size={16} />
                    <span>Past Debates</span>
                  </button>
                  <button 
                    className={styles.profileButton} 
                    onClick={() => navigate('/settings')}
                  >
                    <Settings size={16} />
                    <span>Settings</span>
                  </button>
                  <button 
                    className={styles.logoutButton} 
                    onClick={handleLogout}
                  >
                    <LogOut size={16} />
                    <span>Log out</span>
                  </button>
                </div>
              </div>
            ) : (
              <div className={styles.loginCard}>
                <button 
                  className={styles.signupButton} 
                  onClick={() => navigate('/login')}
                >
                  <LogIn size={16} />
                  <span>Log in to get started</span>
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TopMenuBar;