import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../queries/Queries';
import ReadyModal from '../components/ReadyModal';
import TopMenuBar from '../components/TopMenuBar';
import styles from '../styles/DebateRoom.module.css';
import { getCurrentUserAndProfile } from '../queries/Queries';

const DebateRoom = () => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const [room, setRoom] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [isCreator, setIsCreator] = useState(false);
  const [error, setError] = useState(null);
  const [debateStarted, setDebateStarted] = useState(false);

  useEffect(() => {
    let roomSubscription;

    const initialize = async () => {
      setIsLoading(true);
      try {
        // Get user profile
        const userResult = await getCurrentUserAndProfile();
        if (!userResult) {
          navigate('/login');
          return;
        }
        setUserAndProfile(userResult);

        // Fetch initial room details
        const { data: roomData, error: roomError } = await supabase
          .from('debate_rooms')
          .select(`
            *,
            creator:creator_id(username),
            opponent:opponent_id(username)
          `)
          .eq('id', roomId)
          .single();

        if (roomError) throw roomError;
        if (!roomData) throw new Error('Room not found');

        // Verify user is part of this debate
        if (roomData.creator_id !== userResult.user.id && 
            roomData.opponent_id !== userResult.user.id) {
          throw new Error('You are not authorized to view this debate');
        }

        setRoom(roomData);
        setIsCreator(userResult.user.id === roomData.creator_id);

        // Subscribe to real-time updates
        roomSubscription = supabase
          .channel(`room:${roomId}`)
          .on(
            'postgres_changes',
            {
              event: '*',
              schema: 'public',
              table: 'debate_rooms',
              filter: `id=eq.${roomId}`
            },
            async (payload) => {
              const updatedRoom = payload.new;
              // Fetch the full room data with profiles
              const { data } = await supabase
                .from('debate_rooms')
                .select(`
                  *,
                  creator:creator_id(username),
                  opponent:opponent_id(username)
                `)
                .eq('id', roomId)
                .single();
              
              if (data) {
                setRoom(data);
                if (data.status === 'in_progress') {
                  setDebateStarted(true);
                }
              }
            }
          )
          .subscribe();

      } catch (error) {
        console.error('Error initializing debate room:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    initialize();

    return () => {
      if (roomSubscription) {
        roomSubscription.unsubscribe();
      }
    };
  }, [roomId, navigate]);

  const handleDebateStart = () => {
    setDebateStarted(true);
    // Initialize debate interface here
  };

  if (isLoading) {
    return (
      <div className={styles.container}>
        <TopMenuBar userAndProfile={userAndProfile} />
        <div className={styles.loading}>Loading debate room...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.container}>
        <TopMenuBar userAndProfile={userAndProfile} />
        <div className={styles.error}>
          <h2>Error</h2>
          <p>{error}</p>
          <button 
            className={styles.backButton}
            onClick={() => navigate('/rooms')}
          >
            Back to Rooms
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <TopMenuBar userAndProfile={userAndProfile} />
      
      {!debateStarted && room && (
        <ReadyModal
          isOpen={true}
          room={room}
          isCreator={isCreator}
          onDebateStart={handleDebateStart}
          userAndProfile={userAndProfile}
        />
      )}

      {debateStarted && (
        <div className={styles.debateContainer}>
          {/* Your debate interface components here */}
        </div>
      )}
    </div>
  );
};

export default DebateRoom;