import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserStoredDebates, getProfileData, getCurrentUserAndProfile } from '../queries/Queries';
import { Search, SortAsc, Filter, ChevronLeft, ChevronRight } from 'lucide-react';
import TopMenuBar from '../components/TopMenuBar';
import TopicCard from '../components/TopicCard';
import FriendsTab from '../components/FriendsTab'


const formatDateForSearch = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return ''; // Return empty string if invalid date
  
  // Create different date format variations for matching
  const month = date.toLocaleString('en-US', { month: 'short' }).toLowerCase();
  const fullMonth = date.toLocaleString('en-US', { month: 'long' }).toLowerCase();
  const day = date.getDate();
  const year = date.getFullYear();
  const numericMonth = date.getMonth() + 1;

  return [
    `${month} ${day}`,                    // Nov 13
    `${month} ${day} ${year}`,           // Nov 13 2023
    `${fullMonth} ${day}`,               // November 13
    `${fullMonth} ${day} ${year}`,       // November 13 2023
    `${numericMonth}/${day}`,            // 11/13
    `${numericMonth}-${day}`,            // 11-13
    `${numericMonth}/${day}/${year}`,    // 11/13/2023
    `${numericMonth}-${day}-${year}`,    // 11-13-2023
  ].join('|').toLowerCase();
};

const isDateMatch = (dateString, searchText) => {
  const dateFormats = formatDateForSearch(dateString);
  const searchLower = searchText.toLowerCase();
  
  // Check if search text might be a date
  const potentialDateFormats = formatDateForSearch(searchText);
  
  return dateFormats.includes(searchLower) || potentialDateFormats.includes(dateFormats);
};

const SearchBar = ({ onSearch, initialValue }) => {
  const [localSearchInput, setLocalSearchInput] = useState(initialValue || '');

  const handleSubmit = () => {
    onSearch(localSearchInput);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="relative flex-grow flex h-10">
      <div className="relative flex-grow">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <input
          type="text"
          placeholder="Search debates..."
          value={localSearchInput}
          onChange={(e) => {
            setLocalSearchInput(e.target.value);
            onSearch(e.target.value); // Immediate search on type
          }}
          onKeyPress={handleKeyPress}
          className="h-full w-full pl-10 pr-4 bg-gray-900 rounded-l-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-700 font-manrope"
        />
      </div>
      <button
        onClick={handleSubmit}
        className="h-full px-4 bg-gray-700 hover:bg-gray-600 rounded-r-lg text-white font-manrope transition-colors flex items-center"
      >
        Search
      </button>
    </div>
  );
};

const PaginationControls = ({ currentPage, totalPages, onPageChange }) => {
  const getPageNumbers = () => {
    const pages = [];
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(startPage + 9, totalPages);
    
    // Adjust startPage if we're near the end to always show 10 pages if available
    if (endPage - startPage < 9 && startPage > 1) {
      startPage = Math.max(1, endPage - 9);
    }
    
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    
    return pages;
  };

  return (
    <div className="flex items-center justify-center gap-2 mt-6">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="p-2 rounded-lg bg-gray-900 text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-800 transition-colors"
      >
        <ChevronLeft size={20} />
      </button>
      
      <div className="flex items-center gap-2 font-manrope">
        {getPageNumbers().map((pageNum) => (
          <button
            key={pageNum}
            onClick={() => onPageChange(pageNum)}
            className={`w-8 h-8 rounded-lg flex items-center justify-center transition-colors font-manrope ${
              currentPage === pageNum
                ? 'bg-white text-black font-medium'
                : 'bg-gray-900 text-white hover:bg-gray-800'
            }`}
          >
            {pageNum}
          </button>
        ))}
      </div>

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="p-2 rounded-lg bg-gray-900 text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-800 transition-colors"
      >
        <ChevronRight size={20} />
      </button>
    </div>
  );
};

const ProfilePage = () => {
  const [profileData, setProfileData] = useState(null);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [storedDebates, setStoredDebates] = useState([]);
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get('tab') || 'debates';
  const [activeTab, setActiveTab] = useState(initialTab);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('newest');
  const [filterOption, setFilterOption] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfileData();
    fetchUserAndProfile();
  }, []);

  const fetchProfileData = async () => {
    const data = await getProfileData();
    const debates = await getUserStoredDebates();
    if (data) {
      setProfileData(data);
      setStoredDebates(debates);
    }
  };

  const fetchUserAndProfile = async () => {
    const result = await getCurrentUserAndProfile();
    setUserAndProfile(result);
  };

  function debateIdSetter(debateId) {
    return () => {
      navigate(`/stored-debates/${debateId}`);
    };
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  const getFilteredAndSortedDebates = () => {
    let filtered = [...storedDebates];

    // Apply search filter
    if (searchQuery) {
      filtered = filtered.filter(debate => {
        const searchLower = searchQuery.toLowerCase();
        return (
          debate.topic.toLowerCase().includes(searchLower) ||
          (debate.persona && debate.persona.toLowerCase().includes(searchLower)) ||
          isDateMatch(debate.created_at, searchQuery)
        );
      });
    }

    // Apply result filter
    if (filterOption === 'won') {
      filtered = filtered.filter(debate => debate.debate_result_id === 1);
    } else if (filterOption === 'lost') {
      filtered = filtered.filter(debate => debate.debate_result_id !== 1);
    }

    // Apply sorting
    filtered.sort((a, b) => {
      switch (sortOption) {
        case 'oldest':
          return new Date(a.created_at) - new Date(b.created_at);
        case 'newest':
          return new Date(b.created_at) - new Date(a.created_at);
        default:
          return 0;
      }
    });

    return filtered;
  };

  const getPaginatedDebates = () => {
    const filtered = getFilteredAndSortedDebates();
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filtered.slice(startIndex, endIndex);
  };

  const getTotalPages = () => {
    return Math.ceil(getFilteredAndSortedDebates().length / ITEMS_PER_PAGE);
  };

  // Reset to first page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, sortOption, filterOption]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // Scroll to top of debates section
    document.getElementById('debates-section')?.scrollIntoView({ behavior: 'smooth' });
  };

  const debateColumnMap = (elem) => (
    <TopicCard
      key={elem.id}
      onClick={debateIdSetter(elem.id)}
      didUserWin={elem.debate_result_id === 1}
      opponent={elem.opp_user_id == null ? elem.persona : elem.opp_user_id}
      topic={elem.topic}
      date={formatDate(elem.created_at)}
    />
  );

  const handleSignOut = () => {
    setUserAndProfile(null);
  };

  const TabButton = ({ active, onClick, children }) => (
    <button
      onClick={onClick}
      className={`px-8 py-4 font-manrope text-base transition-all relative ${
        active 
          ? 'text-white font-semibold' 
          : 'text-gray-400 hover:text-gray-200'
      }`}
    >
      {children}
      {active && (
        <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white rounded-t-full"></div>
      )}
    </button>
  );

  const SearchAndFilter = () => (
    <div className="mb-6 space-y-4">
      <div className="flex flex-col md:flex-row gap-4 items-center">
        <SearchBar onSearch={setSearchQuery} initialValue={searchQuery} />

        {/* Sort Dropdown */}
        <div className="relative min-w-[140px] h-10">
          <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <SortAsc className="text-gray-400" size={20} />
          </div>
          <select
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            className="h-full w-full pl-10 pr-4 bg-gray-900 rounded-lg text-white appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-gray-700 font-manrope"
          >
            <option value="newest">Newest First</option>
            <option value="oldest">Oldest First</option>
          </select>
        </div>

        {/* Filter Dropdown */}
        <div className="relative min-w-[140px] h-10">
          <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <Filter className="text-gray-400" size={20} />
          </div>
          <select
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
            className="h-full w-full pl-10 pr-4 bg-gray-900 rounded-lg text-white appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-gray-700 font-manrope"
          >
            <option value="all">All Debates</option>
            <option value="won">Won</option>
            <option value="lost">Lost</option>
          </select>
        </div>
      </div>
    </div>
  );

  const TabContent = () => {
    switch (activeTab) {
      case 'debates':
        return (
          <div className="w-full">
            <SearchAndFilter />
            <div id="debates-section" className="flex flex-col gap-4">
              {getPaginatedDebates().map(debateColumnMap)}
              {getFilteredAndSortedDebates().length > ITEMS_PER_PAGE && (
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={getTotalPages()}
                  onPageChange={handlePageChange}
                />
              )}
              {getFilteredAndSortedDebates().length === 0 && (
                <p className="text-center text-gray-400">No debates found matching your criteria.</p>
              )}
            </div>
          </div>
        );
      case 'stats':
        return (
          <div className="w-full bg-black rounded-lg">
            <p className="text-gray-400 font-manrope">Statistics coming soon...</p>
          </div>
        );
      case 'friends':
        return (
          <FriendsTab />
        );
      default:
        return null;
    }
  };

  if (!profileData) {
    return <div className="flex justify-center items-center h-screen text-xl text-white font-manrope">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-black text-white font-manrope">
      <div className="w-full">
        <TopMenuBar userAndProfile={userAndProfile} onSignOut={handleSignOut} />
      </div>
      
      {/* Profile Section */}
      <div className="w-full px-4 py-8">
        <div className="max-w-4xl mx-auto bg-black rounded-lg p-6 mb-8">
          <div className="flex flex-col md:flex-row gap-6">
            {/* Left Column - Avatar */}
            <div className="flex flex-col items-center gap-4">
              <div className="w-32 h-32 rounded-full overflow-hidden flex-shrink-0 border-2 border-gray-800">
                <img
                  src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                  alt="Profile"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex items-center gap-2 bg-gray-900/50 px-3 py-1 rounded-full">
                <div className="w-2.5 h-2.5 bg-green-500 rounded-full"></div>
                <span className="text-sm text-gray-300">Online Now</span>
              </div>
            </div>

            {/* Right Column - Info */}
            <div className="flex-grow">
              {/* Name and Flag Row */}
              <div className="flex items-center gap-3 mb-4">
                <h1 className="text-3xl font-bold">{profileData.username || profileData.email}</h1>
                <img
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjM1IDY1MCIgeG1sbnM6dj0iaHR0cHM6Ly92ZWN0YS5pby9uYW5vIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDEyMzV2NjUwSDB6Ii8+PGcgZmlsbD0iI2JmMDEwNCI+PHBhdGggZD0iTTAgMGgxMjM1djUwSDB6TTAgMTAwaDEyMzV2NTBIMHpNMCAyMDBoMTIzNXY1MEgwek0wIDMwMGgxMjM1djUwSDB6TTAgNDAwaDEyMzV2NTBIMHpNMCA1MDBoMTIzNXY1MEgwek0wIDYwMGgxMjM1djUwSDB6Ii8+PC9nPjxyZWN0IGZpbGw9IiMyODJhNWQiIHdpZHRoPSI0OTQiIGhlaWdodD0iMzUwIi8+PC9zdmc+"
                  alt="USA"
                  className="w-6 h-4"
                />
              </div>

              {/* Status/Bio */}
              <div className="mb-6">
                <div className="bg-gray-900/30 rounded-lg p-4 text-gray-300 hover:bg-gray-900/40 transition-colors cursor-pointer flex items-center gap-2">
                  <span className="text-gray-400">Enter a status here</span>
                  <svg className="w-4 h-4 text-gray-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                  </svg>
                </div>
              </div>

              {/* Stats Grid */}
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {/* Joined Date */}
                <div className="bg-gray-900/30 rounded-lg p-4 flex flex-col items-center">
                  <svg className="w-5 h-5 text-gray-400 mb-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                  <span className="text-sm text-gray-400">Joined</span>
                  <span className="font-medium">{formatDate(profileData.created_at)}</span>
                </div>

                {/* Number of Debates */}
                <div className="bg-gray-900/30 rounded-lg p-4 flex flex-col items-center">
                  <svg className="w-5 h-5 text-gray-400 mb-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                  </svg>
                  <span className="text-sm text-gray-400">Debates</span>
                  <span className="font-medium">{storedDebates.length}</span>
                </div>

                {/* Friends Count */}
                <div className="bg-gray-900/30 rounded-lg p-4 flex flex-col items-center">
                  <svg className="w-5 h-5 text-gray-400 mb-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  <span className="text-sm text-gray-400">Friends</span>
                  <span className="font-medium">0</span>
                </div>

                {/* Profile Views */}
                <div className="bg-gray-900/30 rounded-lg p-4 flex flex-col items-center">
                  <svg className="w-5 h-5 text-gray-400 mb-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                  <span className="text-sm text-gray-400">Views</span>
                  <span className="font-medium">0</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="max-w-4xl mx-auto">
          <div className="border-b border-gray-800">
            <div className="flex justify-center md:justify-start -mb-px">
              <TabButton 
                active={activeTab === 'debates'} 
                onClick={() => setActiveTab('debates')}
              >
                Past Debates
              </TabButton>
              <TabButton 
                active={activeTab === 'stats'} 
                onClick={() => setActiveTab('stats')}
              >
                Stats
              </TabButton>
              <TabButton 
                active={activeTab === 'friends'} 
                onClick={() => setActiveTab('friends')}
              >
                Friends
              </TabButton>
            </div>
          </div>
          {/* Tab Content Container */}
          <div className="w-full px-4 md:px-0 py-6">
            <TabContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;