import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import styles from "../styles/EvaluationReportCard.module.css";
import {
  calculateCumulativeScores,
  getDisplayScore,
} from "../utils/scoreUtils";
import { generateDebateSummary } from "../utils/api";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { getCurrentUserAndProfile } from "../queries/Queries";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const EvaluationReportCard = ({
  fromStoredDebate,
  evaluations,
  topic,
  personaName,
  onClose,
  onHide,
  messages,
  userSide,
  summary,
  setSummary,
  mode
}) => {
  const [userAndProfile, setUserAndProfile] = useState(null);
  const navigate = useNavigate();

  const handleNewDebate = () => {
    if(userAndProfile){
      navigate('/ai')
      window.location.reload()
    }
    else{
      console.log("here and fromStoredDebate: " + fromStoredDebate)
      fromStoredDebate ? navigate('/signup') : navigate('/ai')
    }
  }

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  useEffect(() => {
    if (!summary && messages.length !== 0 && userAndProfile) {
      generateSummary();
    }
  }, [userAndProfile]);

  const generateSummary = async () => {
    const roundByRoundData = evaluations
      .map((e, index) => {
        const cumulativeScores = calculateCumulativeScores(
          evaluations.slice(0, index + 1)
        );
        const roundScore = getDisplayScore(
          cumulativeScores[cumulativeScores.length - 1]
        );
        return `round ${index + 1}: ${e.side} - score: ${roundScore.toFixed(
          3
        )} - ${e.summary.toLowerCase()}`;
      })
      .join("\n");

    const debateTranscript = messages
      .filter((msg) => msg.role !== "system")
      .map((msg) => `${msg.role.toUpperCase()}: ${msg.content.toLowerCase()}`)
      .join("\n\n");

    try {
      const generatedSummary = await generateDebateSummary(
        topic.toLowerCase(),
        roundByRoundData,
        debateTranscript,
        userSide.toLowerCase()
      );
      setSummary(generatedSummary.toLowerCase());
    } catch (error) {
      console.error("Failed to generate debate summary:", error);
      setSummary("failed to generate debate summary. please try again later.");
    }
  };

  const cumulativeScores = calculateCumulativeScores(evaluations);
  const displayScores = cumulativeScores.map(getDisplayScore);

  const maxScore = Math.max(...displayScores.map(Math.abs));
  const axisMax = Math.ceil(maxScore * 1.2);

  const chartData = {
    labels: evaluations.map((_, index) => `${index + 1}`),
    datasets: [
      {
        label: "cumulative score",
        data: displayScores,
        borderColor: "#4CAF50",
        backgroundColor: "rgba(76, 175, 80, 0.1)",
        tension: 0.1,
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMin: -axisMax,
        suggestedMax: axisMax,
        ticks: {
          callback: (value) => value.toFixed(1),
          color: "#FFFFFF",
        },
        title: {
          display: true,
          text: "score",
          color: "#FFFFFF",
        },
        grid: {
          color: "#333333",
          lineWidth: 1,
        },
      },
      x: {
        title: {
          display: true,
          text: "turn",
          color: "#FFFFFF",
        },
        ticks: {
          color: "#FFFFFF",
        },
        grid: {
          color: "#333333",
          lineWidth: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          forLabel: {
            type: "label",
            yValue: axisMax,
            yAdjust: 10,
            backgroundColor: "transparent",
            content: ["for"],
            color: "#4CAF50",
          },
          againstLabel: {
            type: "label",
            yValue: -axisMax,
            yAdjust: -10,
            backgroundColor: "transparent",
            content: ["against"],
            color: "#FF4500",
          },
        },
      },
    },
  };

  return (
    <div className={styles.evaluationReportCard}>
      <div className={styles.header}>
        <button className={styles.button} onClick={onHide}>
          hide
        </button>
        <h3 className={styles.title}>
          {messages.length === 0 ? (
            <span>seems like nobody showed up!</span>
          ) : (
            <>
      <span className={styles.highlight}>
        {cumulativeScores[cumulativeScores.length - 1] === 0 ? "" : "winner:"}
      </span>
      {' '}
      <span>
        {cumulativeScores[cumulativeScores.length - 1] === 0 ? (
          "it was a tie!"
        ) : (cumulativeScores[cumulativeScores.length - 1] > 0 && userSide.toLowerCase() === "for") || 
           (cumulativeScores[cumulativeScores.length - 1] < 0 && userSide.toLowerCase() === "against") ? (
          userAndProfile?.profile?.username || "you"
        ) : (
          personaName || "ai"
        )}
      </span>
            </>
          )}
        </h3>
        <button className={styles.button} onClick={handleNewDebate}>
          new debate
        </button>
      </div>

      {messages.length > 0 && (
        <>
          <h4 className={styles.topic}>
            <span className={styles.highlight}>topic:</span>{" "}
            <span>{topic.toLowerCase()}</span>
          </h4>
          <div className={styles.chartContainer}>
            <Line data={chartData} options={chartOptions} />
          </div>
          <div className={styles.summaryContainer}>
            <h4>analysis report</h4>
            {userAndProfile ? (
              <ReactMarkdown
                components={{
                  p: ({ node, ...props }) => (
                    <p className={styles.paragraph} {...props} />
                  ),
                  ul: ({ node, ...props }) => (
                    <ul className={styles.list} {...props} />
                  ),
                  li: ({ node, ...props }) => (
                    <li className={styles.listItem} {...props} />
                  ),
                }}
              >
                {summary}
              </ReactMarkdown>
            ) : (
              <div className={styles.signupPrompt}>
                <button
                  onClick={() => navigate("/signup")}
                  className={styles.startButton}
                >
                  sign up to access
                </button>
                <p>
                  already have an account?{" "}
                  <span
                    onClick={() => navigate("/login")}
                    className={styles.loginLink}
                  >
                    login
                  </span>
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default EvaluationReportCard;