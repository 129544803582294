import React from 'react';
import TopMenuBar from "../components/TopMenuBar";
import styles from "../styles/PrivacyPolicy.module.css";

const TermsOfUse = ({ userAndProfile, onSignOut }) => {
  return (
    <div className={styles.privacyPolicyPage}>
      <div className={styles.content}>
        <h1>Terms of Use</h1>
        <p>Last updated: August 17, 2024</p>

        <section>
          <h2>1. Acceptance of Terms</h2>
          <p>By accessing or using DebateArena ("the App"), you agree to be bound by these Terms of Use. If you disagree with any part of these terms, you may not use our services.</p>
        </section>

        <section>
          <h2>2. Description of Service</h2>
          <p>DebateArena is a platform that allows users to engage in debates with AI opponents and, in the future, other users. The App provides various features including customizable AI personas, debate topics, and performance evaluations.</p>
        </section>

        <section>
          <h2>3. User Accounts</h2>
          <ul>
            <li>You may use the App as a guest or by creating an account.</li>
            <li>If you create an account, you are responsible for maintaining the confidentiality of your account and password.</li>
            <li>You agree to provide accurate, current, and complete information during the registration process.</li>
          </ul>
        </section>

        <section>
          <h2>4. User Conduct</h2>
          <p>You agree not to:</p>
          <ul>
            <li>Use the App for any illegal purpose or in violation of any local, state, national, or international law.</li>
            <li>Harass, abuse, or harm another person through your use of the App.</li>
            <li>Impersonate or misrepresent your affiliation with any person or entity.</li>
            <li>Interfere with or disrupt the App or servers or networks connected to the App.</li>
            <li>Use the App to generate or disseminate deliberate misinformation or harmful content.</li>
          </ul>
        </section>

        <section>
          <h2>5. Content</h2>
          <ul>
            <li>You retain ownership of any intellectual property rights that you hold in content you submit to the App.</li>
            <li>By submitting content, you grant DebateArena a worldwide, royalty-free license to use, reproduce, modify, and distribute that content for the purpose of operating and improving the App.</li>
            <li>You are solely responsible for your content and the consequences of submitting and publishing your content on the App.</li>
          </ul>
        </section>

        <section>
          <h2>6. Intellectual Property</h2>
          <p>The App and its original content, features, and functionality are owned by DebateArena and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
        </section>

        <section>
          <h2>7. Premium Features</h2>
          <ul>
            <li>Certain features of the App may be available only with a paid subscription.</li>
            <li>You agree to pay all fees charged to your account based on the fees, charges, and billing terms in effect at the time.</li>
          </ul>
        </section>

        <section>
          <h2>8. Termination</h2>
          <p>We may terminate or suspend your account and bar access to the App immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever, including but not limited to a breach of the Terms.</p>
        </section>

        <section>
          <h2>9. Limitation of Liability</h2>
          <p>In no event shall DebateArena, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the App.</p>
        </section>

        <section>
          <h2>10. Disclaimer</h2>
          <ul>
            <li>Your use of the App is at your sole risk. The App is provided on an "AS IS" and "AS AVAILABLE" basis.</li>
            <li>We do not warrant that the results that may be obtained from the use of the App will be accurate or reliable.</li>
          </ul>
        </section>

        <section>
          <h2>11. Changes to Terms</h2>
          <p>We reserve the right to modify or replace these Terms at any time. We will provide notice of any significant changes.</p>
        </section>

        <section>
          <h2>12. Governing Law</h2>
          <p>These Terms shall be governed and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law provisions.</p>
        </section>

        <section>
          <h2>13. Contact Us</h2>
          <p>If you have any questions about these Terms, please contact us at info@debatearena.app.</p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfUse;