import React from 'react';

const TopicCard = ({ onClick, didUserWin, opponent, topic, date }) => {
  return (
    <div
      className={`bg-black rounded-lg p-4 w-full border ${
        didUserWin ? 'border-green-500' : 'border-red-500'
      }`}
      style={{ minHeight: '70px' }}
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between mb-2">
          <span className="text-xs text-gray-400">{date}</span>
          <span className={`text-xs font-medium ${didUserWin ? 'text-green-500' : 'text-red-500'}`}>
            {didUserWin ? 'Won' : 'Lost'}
          </span>
        </div>
        <h3 className="text-base font-semibold mb-2 overflow-hidden whitespace-nowrap text-ellipsis">
          {topic}
        </h3>
        <div className="flex justify-between items-center mt-auto">
          <p className="text-xs text-gray-400">
            Opponent: <span className="text-white">{opponent}</span>
          </p>
          <a 
            onClick={onClick}
            className="text-white underline cursor-pointer font-manrope text-xs"
          >
            View
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopicCard;