import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styles from '../styles/SignUpModal.module.css';
import googleLogo from "../images/google_logo.png";
import { handleGoogleSignIn } from "../queries/Queries.js";

const SignUpModal = ({ onClose }) => {
  const navigate = useNavigate();

  const handleSignUp = () => {
    navigate('/signup');
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2>sign up for fact-checking</h2>
        <p className={styles.subtext}>plus get access to debate evaluations, unlimited personas and more!</p>
        <button onClick={handleSignUp} className={styles.signUpButton}>
          sign up
        </button>
        <div className={styles.separator}>or</div>
        <button onClick={handleGoogleSignIn} className={styles.googleAuthButton}>
          <img src={googleLogo} alt="Google Logo" className={styles.googleLogo} />
          sign in with Google
        </button>
        <p className={styles.loginLink}>
          already have an account? <Link to="/login">login</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUpModal;