import React, { useState, useEffect, useRef } from "react";
import { Mic, Square, ChevronLeft, ChevronRight } from "lucide-react";
import { modes } from "../constants/debateModes";
import styles from "../styles/DebateArena.module.css";
import { calculateCumulativeScores, getDisplayScore } from "../utils/scoreUtils";

function DebateArena({
  arenaRef,
  animationFinished,
  examSide,
  side,
  topic,
  mode,
  currentRound,
  divArg,
  examArg,
  messages,
  chatBoxRef,
  turnReady,
  debateConcluded,
  prepTimeLeft,
  debateTimeLeft,
  input,
  setInput,
  isStreaming,
  isRecording,
  handleSubmit,
  handleEndPrep,
  startRecording,
  stopRecording,
  handleEndDebate,
  allSources,
  globalDebateTime,
  evaluations,
  showEvaluationReport,
  handleAIFirstTurn, 
  showAIFirstTurn,
  setShowAIFirstTurn
}) {
  const isBlitzMode = mode === "Blitz";
  const isCrossExamMode = mode === "Cross-Examine";

  const [showAdditionalSources, setShowAdditionalSources] = useState(false);
  const [cumulativeScore, setCumulativeScore] = useState(0);
  const [currentSummary, setCurrentSummary] = useState("");
  const evaluationSummaryRef = useRef(null);
  const evaluationTextRef = useRef(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const adjustFontSize = () => {
      if (evaluationSummaryRef.current && evaluationTextRef.current) {
        let fontSize = 1; // Start fresh at 1em each time
        evaluationTextRef.current.style.fontSize = `${fontSize}em`;
        const maxHeight = evaluationSummaryRef.current.clientHeight;
        
        // If text is too big, reduce size until it fits
        while (evaluationTextRef.current.scrollHeight > maxHeight && fontSize > 0.1) {
          fontSize -= 0.1;
          evaluationTextRef.current.style.fontSize = `${fontSize}em`;
        }
        
        // If text is smaller than necessary, increase size until it just fits
        while (fontSize < 1 && evaluationTextRef.current.scrollHeight <= maxHeight) {
          fontSize += 0.1;
          evaluationTextRef.current.style.fontSize = `${fontSize}em`;
          
          // Check if increasing once more would overflow
          if (evaluationTextRef.current.scrollHeight > maxHeight) {
            fontSize -= 0.1; // Go back one step
            evaluationTextRef.current.style.fontSize = `${fontSize}em`;
            break;
          }
        }
      }
    };
  
    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);
  
    return () => window.removeEventListener('resize', adjustFontSize);
  }, [currentSummary]);

  useEffect(() => {
    if (evaluations.length > 0) {
      const scores = calculateCumulativeScores(evaluations);
      setCumulativeScore(scores[scores.length - 1]);
      setCurrentSummary(evaluations[evaluations.length - 1].summary);
    }
  }, [evaluations]);

  const amplifiedPercentage =
    (Math.sign(cumulativeScore) *
      Math.pow(Math.abs(cumulativeScore) / 100, 0.7) +
      1) *
    50;

  const isForWinning = cumulativeScore >= 0;
  const displayScore = getDisplayScore(cumulativeScore);

  const toggleAdditionalSources = () => {
    setShowAdditionalSources(!showAdditionalSources);
  };

  const isBlinking = (time) => time <= 3;

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className={styles["debate-arena"]} ref={arenaRef}>
      {isCrossExamMode && (
        <>
          <div className={`${styles["cross-exam-sidebar"]} ${sidebarOpen ? styles["sidebar-open"] : ""}`}>
            <p className={`${styles["side-labels"]} ${styles[examSide.toLowerCase()]}`}>
              {examSide === "Examinee"
                ? "Defend the argument given below"
                : "Question the argument given below"}
            </p>
            <h3>{examSide === "Examinee" ? "Your" : "Opponent's"} Claim</h3>
            <p>{divArg?.claim}</p>
            <h3>Warrant</h3>
            <p>{divArg?.warrant}</p>
            <h3>Impact</h3>
            <p>{divArg?.impact}</p>
          </div>
          <button className={styles["sidebar-toggle"]} onClick={toggleSidebar}>
            {sidebarOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
          </button>
        </>
      )}
      <div className={styles["cross-exam-arena"]}>
        <div className={styles["evaluation-bar-container"]}>
          <div className={styles["evaluation-bar"]}>
            <div
              className={styles["evaluation-bar-fill"]}
              style={{ width: `${amplifiedPercentage}%` }}
            >
              {isForWinning && (
                <span className={styles["score-display-for"]}>
                  {displayScore.toFixed(1)}
                </span>
              )}
            </div>
            {!isForWinning && (
              <span className={styles["score-display-against"]}>
                {displayScore.toFixed(1)}
              </span>
            )}
          </div>
        </div>
        {true && (
          <>
            
            <div className={styles["dynamic-boxes"]}>
              <div className={styles["evaluation-summary"]} ref={evaluationSummaryRef}>
                <div className={styles["evaluation-summary-text"]} ref={evaluationTextRef}>
                  {currentSummary}
                </div>
              </div>
              <div className={styles["arena-labels"]}>
                <span
                  className={`${styles["side-labels"]} ${styles.for} ${
                    side === "For" ? styles.active : ""
                  }`}
                >
                  For
                </span>
                <span
                  className={`${styles["side-labels"]} ${styles.against} ${
                    side === "Against" ? styles.active : ""
                  }`}
                >
                  Against
                </span>
              </div>
              <div className={styles["chat-box"]} ref={chatBoxRef}>
                {(mode === "Blitz") && showAIFirstTurn && <button 
                  className={styles["ai-assist-button"]}
                  onClick={handleAIFirstTurn}
                  disabled={isStreaming || !turnReady || debateConcluded}
                >
                  Don't know what to say? Click here to let AI go first
                </button>}
                <div className={styles["topic-message"]}>{topic}</div>
                {mode === "Standard" && (
                  <div className={styles["round-message"]}>
                    {modes[mode].rounds[currentRound].name}
                  </div>
                )}
                {messages
                  .filter((msg) => msg.role !== "system")
                  .map((msg, index) => (
                    <div
                      key={index}
                      className={`${styles.message} ${styles[msg.role]}`}
                    >
                      {msg.content}
                    </div>
                  ))}
              </div>
              
            </div>
            <form
              onSubmit={(e) => handleSubmit(e)}
              className={styles["argument-form"]}
            >
              {isBlitzMode || (isCrossExamMode && turnReady) ? (
                <div className={styles["timer-display"]}>
                  Debate:{" "}
                  <span
                    className={
                      isBlinking(globalDebateTime) ? styles["timer-blink"] : ""
                    }
                  >
                    {globalDebateTime}s
                  </span>
                  {turnReady && globalDebateTime > debateTimeLeft && (
                    <div className={styles["turn-time"]}>
                      Turn:{" "}
                      <span
                        className={
                          isBlinking(debateTimeLeft)
                            ? styles["timer-blink"]
                            : ""
                        }
                      >
                        {debateTimeLeft}s
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {!turnReady && !debateConcluded && !isStreaming && (
                    <div className={styles["timer-display"]}>
                      Prep:{" "}
                      <span
                        className={
                          isBlinking(prepTimeLeft) ? styles["timer-blink"] : ""
                        }
                      >
                        {prepTimeLeft}s
                      </span>
                    </div>
                  )}
                  {turnReady && !debateConcluded && (
                    <div className={styles["timer-display"]}>
                      Debate:{" "}
                      <span
                        className={
                          isBlinking(debateTimeLeft)
                            ? styles["timer-blink"]
                            : ""
                        }
                      >
                        {debateTimeLeft}s
                      </span>
                    </div>
                  )}
                </>
              )}
              <textarea
                id={styles["argument-input"]}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={
                  isStreaming || !turnReady || debateConcluded || isRecording
                }
                placeholder={
                  isStreaming
                    ? ""
                    : turnReady
                    ? "Type your argument OR use Voice input."
                    : "Use this time to prepare your arguments"
                }
                rows="4"
              />
              {turnReady && !debateConcluded && (
                <div className={styles["recording-controls"]}>
                  {!isRecording ? (
                    <button
                      type="button"
                      onClick={startRecording}
                      disabled={isStreaming || !turnReady || debateConcluded}
                      className={styles["record-button"]}
                    >
                      <Mic size={24} />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={stopRecording}
                      className={styles["stop-record-button"]}
                    >
                      <Square size={24} />
                    </button>
                  )}
                </div>
              )}
              {isStreaming ? (
                <button disabled>Waiting for Opponent</button>
              ) : (mode === "Standard" || mode === "Cross-Examine") &&
                !turnReady &&
                !debateConcluded ? (
                <button
                  type="button"
                  onClick={handleEndPrep}
                  disabled={debateConcluded}
                >
                  End Prep
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={!turnReady || debateConcluded || isRecording}
                >
                  Send
                </button>
              )}
              {!isStreaming ? (
                <div>
                  <div
                    className={styles["end-debate"]}
                    onClick={handleEndDebate}
                  >
                    {!debateConcluded ? "End Debate" : null}
                  </div>
                  <div
                    className={styles["end-debate"]}
                    onClick={handleEndDebate}
                  >
                    {debateConcluded && !showEvaluationReport
                      ? "Show Evaluation Report"
                      : null}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </form>
            {mode === "Standard" && allSources.length > 0 && (
              <div className={styles["all-sources-container"]}>
                <h3>Sources:</h3>
                <div className={styles["sources-grid"]}>
                  {allSources.slice(0, 5).map((roundSources, index) => (
                    <div key={index} className={styles["round-sources"]}>
                      <h4>Round {roundSources.round}</h4>
                      <ul>
                        {roundSources.sources.map((source, sourceIndex) => (
                          <li
                            key={sourceIndex}
                            className={styles["source-item"]}
                          >
                            <a
                              href={source.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              title={source.title}
                            >
                              {source.title.length > 30
                                ? source.title.substring(0, 30) + "..."
                                : source.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                {allSources.length > 5 && (
                  <div className={styles["additional-sources"]}>
                    <button
                      className={styles["additional-sources-button"]}
                      onClick={toggleAdditionalSources}
                    >
                      {showAdditionalSources ? "Hide" : "Show"} Additional
                      Sources
                    </button>
                    <div
                      className={`${styles["sources-grid"]} ${
                        showAdditionalSources ? "" : styles.hidden
                      }`}
                    >
                      {allSources.slice(5).map((roundSources, index) => (
                        <div
                          key={index + 5}
                          className={styles["round-sources"]}
                        >
                          <h4>Round {roundSources.round}</h4>
                          <ul>
                            {roundSources.sources.map((source, sourceIndex) => (
                              <li
                                key={sourceIndex}
                                className={styles["source-item"]}
                              >
                                <a
                                  href={source.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title={source.title}
                                >
                                  {source.title.length > 30
                                    ? source.title.substring(0, 30) + "..."
                                    : source.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DebateArena;