import React, { useState, useEffect } from 'react';
import { Check, Loader, Swords } from 'lucide-react';
import styles from '../styles/ReadyModal.module.css';
import { updateReadyState } from '../queries/roomQueries';

const ReadyModal = ({
  isOpen,
  room,
  isCreator,
  onDebateStart,
  userAndProfile
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [modalVisible, setModalVisible] = useState(isOpen);

  useEffect(() => {
    setModalVisible(isOpen || countdown !== null);
  }, [isOpen, countdown]);

  useEffect(() => {
    if (room?.creator_ready && room?.opponent_ready) {
      setCountdown(3);
    }
  }, [room?.creator_ready, room?.opponent_ready]);

  useEffect(() => {
    if (countdown === null) return;

    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      const timer = setTimeout(() => {
        onDebateStart?.();
        // Keep the modal open briefly to display "Begin!"
        setTimeout(() => setModalVisible(false), 1000);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown, onDebateStart]);

  const handleReadyClick = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const { error } = await updateReadyState(room.id, isCreator);
      if (error) throw new Error(error);
    } catch (err) {
      setError('Failed to update ready state. Please try again.');
      console.error('Ready state error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!modalVisible || !room) return null;

  const bothReady = room.creator_ready && room.opponent_ready;
  const userReady = isCreator ? room.creator_ready : room.opponent_ready;

  const getReadyTimeRemaining = (deadline) => {
    if (!deadline) return '2:00';
    const now = new Date();
    const deadlineDate = new Date(deadline);
    const diffSeconds = Math.max(0, Math.floor((deadlineDate - now) / 1000));
    const minutes = Math.floor(diffSeconds / 60);
    const seconds = diffSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className={styles.overlay}>
      {/* Countdown Overlay */}
      {countdown !== null ? (
        <div className={`${styles.countdownOverlay} ${countdown === 0 ? styles.final : ''}`}>
          <div className={styles.countdownContent}>
            {countdown === 0 ? (
              <>
                <Swords size={80} className={styles.swordsIcon} />
                <div className={styles.beginText}>Begin!</div>
              </>
            ) : (
              <>
                <div className={styles.countdownNumber}>{countdown}</div>
                <div className={styles.countdownText}>Starting in...</div>
              </>
            )}
          </div>
        </div>
      ) : null}

      {/* Modal Content */}
      <div className={styles.modal}>
        <h2 className={styles.title}>Ready Check</h2>
        <p className={styles.topic}>{room.topic}</p>

        {error && <p className={styles.error}>{error}</p>}

        <div className={styles.players}>
          <div
            className={`${styles.playerCard} ${styles.forSide} ${
              room.creator_ready ? styles.ready : ''
            }`}
          >
            <div className={styles.playerContent}>
              <div className={styles.playerInfo}>
                <span className={styles.playerName}>
                  {room.creator?.username || 'Unknown'}
                  {isCreator && ' (You)'}
                </span>
                <span className={styles.sideIndicator}>For</span>
              </div>
              <div className={styles.readyStatus}>
                {room.creator_ready ? (
                  <div className={styles.readyBadge}>
                    <Check size={16} />
                    Ready
                  </div>
                ) : (
                  <div className={styles.waitingBadge}>Waiting...</div>
                )}
              </div>
            </div>
            <div className={styles.glowBorder} />
          </div>

          <div className={styles.versusContainer}>
            <div className={styles.versusCircle}>
              <Swords size={24} />
            </div>
            <div className={styles.versusLine} />
          </div>

          <div
            className={`${styles.playerCard} ${styles.againstSide} ${
              room.opponent_ready ? styles.ready : ''
            }`}
          >
            <div className={styles.playerContent}>
              <div className={styles.playerInfo}>
                <span className={styles.playerName}>
                  {room.opponent?.username || 'Unknown'}
                  {!isCreator && ' (You)'}
                </span>
                <span className={styles.sideIndicator}>Against</span>
              </div>
              <div className={styles.readyStatus}>
                {room.opponent_ready ? (
                  <div className={styles.readyBadge}>
                    <Check size={16} />
                    Ready
                  </div>
                ) : (
                  <div className={styles.waitingBadge}>Waiting...</div>
                )}
              </div>
            </div>
            <div className={styles.glowBorder} />
          </div>
        </div>

        <button
          className={`${styles.readyButton} ${userReady ? styles.ready : ''}`}
          onClick={handleReadyClick}
          disabled={userReady || isLoading}
        >
          {isLoading ? (
            <Loader className={styles.spinner} size={16} />
          ) : userReady ? (
            'Ready!'
          ) : (
            'Ready Up'
          )}
        </button>

        <div className={styles.timer}>
          Ready check expires in: {getReadyTimeRemaining(room.ready_deadline)}
        </div>
      </div>
    </div>
  );
};

export default ReadyModal;
