import { supabase } from './Queries';

export const fetchActiveRooms = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { userRooms: [], otherRooms: [] };

    // First handle expired rooms
    await handleRoomExpiration();

    // Fetch all active rooms including profile data
    const { data: rooms, error } = await supabase
      .from('debate_rooms')
      .select(`
        *,
        creator:creator_id(username),
        opponent:opponent_id(username)
      `)
      .eq('is_active', true)
      .order('created_at', { ascending: false });

    if (error) throw error;

    const userRooms = [];
    const otherRooms = [];

    rooms.forEach(room => {
      const transformedRoom = {
        id: room.id,
        topic: room.topic,
        createdBy: room.creator?.username || 'Unknown',
        expiresIn: getTimeRemaining(room.expire_at),
        creatorSide: room.creator_side,
        status: room.status,
        isCreator: session.user.id === room.creator_id,
        opponent: room.opponent?.username || null,
        creator_id: room.creator_id,
        creator_ready: room.creator_ready,
        opponent_ready: room.opponent_ready,
        ready_phase_started: room.ready_phase_started,
        ready_deadline: room.ready_deadline
      };

      if (room.creator_id === session.user.id || room.opponent_id === session.user.id) {
        userRooms.push(transformedRoom);
      } else if (room.status === 'waiting' && !room.opponent_id) {
        otherRooms.push(transformedRoom);
      }
    });

    return { userRooms, otherRooms };
  } catch (error) {
    console.error('Error fetching active rooms:', error);
    return { error: error.message };
  }
};

export const createDebateRoom = async (topic, creatorSide) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { error: 'Not authenticated' };

    const expireAt = new Date();
    expireAt.setHours(expireAt.getHours() + 3);

    const { data, error } = await supabase
      .from('debate_rooms')
      .insert({
        topic,
        creator_id: session.user.id,
        creator_side: creatorSide,
        expire_at: expireAt.toISOString(),
        is_active: true,
        status: 'waiting'
      })
      .select(`
        *,
        creator:creator_id(username),
        opponent:opponent_id(username)
      `)
      .single();

    if (error) throw error;
    return { data };
  } catch (error) {
    console.error('Error creating debate room:', error);
    return { error: error.message };
  }
};

export const joinDebateRoom = async (roomId) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { error: 'Not authenticated' };

    // First get the room with a lock
    const { data: room, error: fetchError } = await supabase
      .from('debate_rooms')
      .select('*')
      .eq('id', roomId)
      .eq('is_active', true)
      .eq('status', 'waiting')
      .is('opponent_id', null)
      .single();

    if (fetchError) {
      if (fetchError.code === 'PGRST116') {
        return { error: 'Room is no longer available' };
      }
      throw fetchError;
    }

    // Additional validation
    if (room.creator_id === session.user.id) {
      return { error: 'Cannot join your own room' };
    }

    const readyDeadline = new Date();
    readyDeadline.setMinutes(readyDeadline.getMinutes() + 2);

    // Update the room
    const { data: updatedRoom, error: updateError } = await supabase
      .rpc('join_debate_room', { 
        room_id: roomId,
        joiner_id: session.user.id,
        ready_deadline: readyDeadline.toISOString()
      });

    if (updateError) throw updateError;
    if (!updatedRoom) return { error: 'Failed to join room' };

    return { data: updatedRoom };
  } catch (error) {
    console.error('Error joining room:', error);
    return { error: error.message || 'Failed to join room' };
  }
};

export const updateReadyState = async (roomId, isCreator) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { error: 'Not authenticated' };

    // Single update call for ready state
    const { data, error } = await supabase
      .from('debate_rooms')
      .update(isCreator ? { creator_ready: true } : { opponent_ready: true })
      .eq('id', roomId)
      .select()
      .single();

    if (error) {
      console.error('Update error:', error);
      return { error: 'Failed to update ready state' };
    }

    // If both are ready, update to in_progress
    if (data.creator_ready && data.opponent_ready) {
      const { data: startedRoom, error: startError } = await supabase
        .from('debate_rooms')
        .update({ status: 'in_progress' })
        .eq('id', roomId)
        .select()
        .single();

      if (startError) {
        console.error('Start error:', startError);
        return { error: 'Failed to start debate' };
      }

      return { data: startedRoom };
    }

    return { data };
  } catch (error) {
    console.error('Error updating ready state:', error);
    return { error: 'An unexpected error occurred' };
  }
};

export const checkNotifications = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return null;

    // Check for rooms where user is creator
    const { data: creatorRoom } = await supabase
      .from('debate_rooms')
      .select(`
        *,
        opponent:opponent_id(username)
      `)
      .eq('creator_id', session.user.id)
      .eq('creator_notified', false)
      .eq('status', 'ready_phase')
      .is('opponent_id', 'not.null')
      .single();

    if (creatorRoom) {
      await supabase
        .from('debate_rooms')
        .update({ creator_notified: true })
        .eq('id', creatorRoom.id);
      
      return {
        type: 'creator',
        room: creatorRoom
      };
    }

    // Check for rooms where user is opponent
    const { data: opponentRoom } = await supabase
      .from('debate_rooms')
      .select(`
        *,
        creator:creator_id(username)
      `)
      .eq('opponent_id', session.user.id)
      .eq('opponent_notified', false)
      .eq('status', 'ready_phase')
      .single();

    if (opponentRoom) {
      await supabase
        .from('debate_rooms')
        .update({ opponent_notified: true })
        .eq('id', opponentRoom.id);
      
      return {
        type: 'opponent',
        room: opponentRoom
      };
    }

    return null;
  } catch (error) {
    console.error('Error checking notifications:', error);
    return { error: error.message };
  }
};

export const handleRoomExpiration = async () => {
  try {
    const now = new Date().toISOString();
    
    const { data, error } = await supabase
      .from('debate_rooms')
      .update({ 
        is_active: false,
        status: 'expired'
      })
      .eq('is_active', true)
      .or(`status.eq.waiting,status.eq.ready_phase`)
      .lt('expire_at', now)
      .select();

    if (error) throw error;
    return { data };
  } catch (error) {
    console.error('Error handling room expiration:', error);
    return { error: error.message };
  }
};

export const subscribeToRoomUpdates = (roomId, callback) => {
  return supabase
    .channel(`room:${roomId}`)
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'debate_rooms',
        filter: `id=eq.${roomId}`
      },
      callback
    )
    .subscribe();
};

const getTimeRemaining = (expireAt) => {
  const now = new Date();
  const expiry = new Date(expireAt);
  const diffMs = expiry - now;
  
  const hours = Math.floor(diffMs / (1000 * 60 * 60));
  const minutes = Math.round((diffMs % (1000 * 60 * 60)) / (1000 * 60));
  
  if (hours <= 0 && minutes <= 0) return 'Expired';
  return `${hours}h ${minutes}m`;
};