import React, { useState, useEffect, useRef } from "react";
import { generateMotion, generateMotionInfo } from "../utils/api";
import { getCurrentUserAndProfile } from "../queries/Queries";
import { useNavigate } from "react-router-dom";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { FiEdit3, FiRefreshCw } from "react-icons/fi";
import styles from "../styles/BpSetupPanel.module.css";

const BpSetupPanel = ({
  side,
  setSide,
  debateStarted,
  handleStartDebate,
  motion,
  setMotion,
  bpInfo,
  setBpInfo,
}) => {
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [motionType, setMotionType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const navigate = useNavigate();
  const textareaRef = useRef(null);
  const infoButtonRef = useRef(null);

  useEffect(() => {
    const generateInitialMotion = async () => {
      try {
        const newMotion = await generateMotion("completely random");
        setMotion(newMotion.toLowerCase());
        const newInfo = await generateMotionInfo(newMotion);
        setBpInfo(newInfo);
        setIsLoading(false);
      } catch (error) {
        console.error("Error generating initial motion:", error);
        setIsLoading(false);
      }
    };
    generateInitialMotion();
    setSide("random");
  }, [setMotion, setBpInfo]);

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.max(textarea.scrollHeight - 23, 70)}px`;
    }
  };

  const handleTextareaChange = (e) => {
    console.log("changed here")
    setMotion(e.target.value.toLowerCase());
    adjustTextareaHeight();
  };

  const handleMotionTypeChange = async (e) => {
    const newMotionType = e.target.value;
    setMotionType(newMotionType);
    console.log("Motion type changed to:", newMotionType);

    if (newMotionType && newMotionType !== "choose your own motion") {
      setIsLoading(true);
      try {
        const newMotion = await generateMotion(newMotionType);
        console.log("New motion received:", newMotion);
        setMotion(newMotion.toLowerCase());
        const newInfo = await generateMotionInfo(newMotion);

        setBpInfo(newInfo);
        adjustTextareaHeight();
      } catch (error) {
        console.error("Error generating motion:", error);
      } finally {
        setIsLoading(false);
      }
    } else if (newMotionType === "choose your own motion") {
      setMotion("");
      setBpInfo([]);
    }
  };

  const handleReroll = async () => {
    let usedMotionType = motionType === ("choose your own motion" || "customize motion") ? "completely random" : motionType
    console.log("used Motion type is " + usedMotionType + " and motion type is " + motionType)
    setIsLoading(true);
    try {
      const newMotion = await generateMotion(usedMotionType);
      console.log("Rerolled motion:", newMotion);
      setMotion(newMotion.toLowerCase());
      const newInfo = await generateMotionInfo(newMotion);
      setBpInfo(newInfo);
      adjustTextareaHeight();
    } catch (error) {
      console.error("Error generating motion:", error);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    adjustTextareaHeight();
    window.addEventListener('resize', adjustTextareaHeight);
    return () => window.removeEventListener('resize', adjustTextareaHeight);
  }, []);

  if (!userAndProfile) {
    return (
      <div className={styles.signupPrompt}>
        <button onClick={() => navigate("/signup")} className={styles.startButton}>
          sign up to access
        </button>
        <p>
          already have an account?{" "}
          <span onClick={() => navigate("/login")} className={styles.loginLink}>
            login
          </span>
        </p>
      </div>
    );
  }

  return (
    <div className={styles.setupPanelWrapper}>
      <div className={styles.setupPanel}>
        <div className={styles.panelContent}>
          <div className={styles.section}>
            <div className={styles.motionRow}>
              <h3 className={styles.motionLabel}>motion</h3>
              <select
                className={styles.modeSelect}
                value={motionType}
                onChange={handleMotionTypeChange}
                disabled={debateStarted}
              >
                <option value="customize motion">customize motion</option>
                <option value="choose your own motion">write your own motion</option>
                <option value="policy">policy</option>
                <option value="value">value</option>
                <option value="fact">fact</option>
                <option value="classic">classic</option>
                <option value="actor motion">actor motion</option>
                <option value="more harm than good">more harm than good motion</option>
                <option value="this house supports">this house supports</option>
                <option value="this house opposes">this house opposes</option>
                <option value="this house believes that">this house believes that</option>
                <option value="this house regrets">this house regrets</option>
                <option value="this house welcomes">this house welcomes</option>
                <option value="economic motions">economic motions</option>
                <option value="right to x">this house believes in the right to x</option>
                <option value="ir motions">ir motions</option>
                <option value="moral motions">moral motions</option>
              </select>
            </div>
            <div className={styles.topicInputContainer}>
              <textarea
                ref={textareaRef}
                value={isLoading ? "Loading..." : motion}
                onChange={handleTextareaChange}
                placeholder="enter a motion (e.g. Argues in favor of the motion, providing reasons why banning alcohol would benefit society, such as reducing health problems, decreasing crime rates, or improving public safety.)"
                className={styles.input}
                disabled={debateStarted || isLoading}
                rows={1}
              />
              <button
                className={styles.rerollButton}
                onClick={handleReroll}
                disabled={debateStarted || isLoading}
              >
                <FiRefreshCw /> reroll
              </button>
            </div>
            <div className={styles.buttonContainer}>
                  <button
                    className={styles.infoButton}
                    onClick={() => setShowInfoPopup(true)}
                    disabled={debateStarted}
                    ref={infoButtonRef}
                  >
                    show info <MdOutlineZoomOutMap />
                  </button>
            </div>
          </div>

          <hr className={styles.divider} />

          <div className={styles.section}>
            <h3>choose your side</h3>
            <div className={styles.sideButtons}>
              <button
                className={`${styles.sideButton} ${styles.forButton} ${
                  side === "gov" ? styles.active : ""
                }`}
                onClick={() => !debateStarted && setSide("gov")}
                disabled={debateStarted}
              >
                gov
              </button>
              <button
                className={`${styles.sideButton} ${styles.randomButton} ${
                  side === "random" ? styles.active : ""
                }`}
                onClick={() => !debateStarted && setSide("random")}
                disabled={debateStarted}
              >
                <h3>?</h3>
                <h3>randomLY ASSIGN</h3>
              </button>
              <button
                className={`${styles.sideButton} ${styles.againstButton} ${
                  side === "opp" ? styles.active : ""
                }`}
                onClick={() => !debateStarted && setSide("opp")}
                disabled={debateStarted}
              >
                opp
              </button>
            </div>
          </div>

          <div className={styles.startButtonContainer}>
            <button
              className={styles.startButton}
              onClick={handleStartDebate}
              disabled={debateStarted}
            >
              start debate
            </button>
          </div>
        </div>
      </div>
      {showInfoPopup && (
        <InfoPopup info={bpInfo} onClose={() => setShowInfoPopup(false)} buttonRef={infoButtonRef} />
      )}
    </div>
  );
};

const InfoPopup = ({ info, onClose, buttonRef }) => {
  const popupRef = useRef(null);
  const [animationClass, setAnimationClass] = useState(styles.popupHidden);

  useEffect(() => {
    if (buttonRef.current && popupRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const popupRect = popupRef.current.getBoundingClientRect();

      popupRef.current.style.transformOrigin = `${buttonRect.left + buttonRect.width / 2 - popupRect.left}px ${buttonRect.top + buttonRect.height / 2 - popupRect.top}px`;
      
      setTimeout(() => setAnimationClass(styles.popupVisible), 50);
    }
  }, []);

  return (
    <div className={`${styles.popupOverlay} ${animationClass}`} onClick={onClose}>
      <div className={styles.popup} ref={popupRef} onClick={(e) => e.stoppropagation()}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <h2 className={styles.popupTitle}>INFO</h2>
        <ul className={styles.infoBullets}>
          {info.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BpSetupPanel;