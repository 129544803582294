export class DeepgramTranscription {
    constructor(apiKey) {
      this.apiKey = apiKey;
      this.socket = null;
      this.mediaRecorder = null;
      this.stream = null;
    }
  
    async connect(onTranscriptCallback) {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        
        if (!MediaRecorder.isTypeSupported('audio/webm')) {
          throw new Error('Browser not supported');
        }
  
        this.mediaRecorder = new MediaRecorder(this.stream, {
          mimeType: 'audio/webm',
        });
  
        // Using the token protocol method as shown in the Deepgram docs
        this.socket = new WebSocket('wss://api.deepgram.com/v1/listen', [
          'token',
          this.apiKey
        ]);
  
        this.socket.onopen = () => {
          console.log('Deepgram connection opened');
          this.mediaRecorder.addEventListener('dataavailable', async (event) => {
            if (event.data.size > 0 && this.socket?.readyState === 1) {
              this.socket.send(event.data);
            }
          });
          this.mediaRecorder.start(1000);
        };
  
        this.socket.onmessage = (message) => {
          const received = JSON.parse(message.data);
          const transcript = received.channel.alternatives[0].transcript;
          if (transcript && received.is_final) {
            onTranscriptCallback(transcript);
          }
        };
  
        this.socket.onclose = (event) => {
          console.log('Deepgram connection closed:', event.code, event.reason);
        };
  
        this.socket.onerror = (error) => {
          console.error('Deepgram error:', error);
        };
  
        return true;
      } catch (error) {
        console.error('Error initializing:', error);
        return false;
      }
    }
  
    disconnect() {
      if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop();
      }
      
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
      }
      
      if (this.socket) {
        this.socket.close();
      }
  
      this.mediaRecorder = null;
      this.stream = null;
      this.socket = null;
    }
  }