import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/UserStats.module.css';

function UserStats() {
  const userStats = {
    username: 'DebateGod',
    elo: 1850,
    botElo: 2000,
    level: 42,
    gamesPlayed: 532,
    winRate: 68.4,
    lastOnline: '2 hours ago',
  };

  useEffect(() => {
    document.body.classList.add(styles.statsPage);
    return () => {
      document.body.classList.remove(styles.statsPage);
    };
  }, []);

  return (
    <div className={styles.statsContainer}>
      <Link to="/" className={styles.backArrow}>
        &#8592;
      </Link>
      <h2 className={styles.title}>{userStats.username}'s Stats</h2>
      <div className={styles.statsGrid}>
        <div className={styles.statItem}>
          <h3>ELO</h3>
          <p>{userStats.elo}</p>
        </div>
        <div className={styles.statItem}>
          <h3>Bot ELO</h3>
          <p>{userStats.botElo}</p>
        </div>
        <div className={styles.statItem}>
          <h3>Level</h3>
          <p>{userStats.level}</p>
        </div>
        <div className={styles.statItem}>
          <h3>Games</h3>
          <p>{userStats.gamesPlayed}</p>
        </div>
        <div className={styles.statItem}>
          <h3>Win Rate</h3>
          <p>{userStats.winRate}%</p>
        </div>
        <div className={styles.statItem}>
          <h3>Last Online</h3>
          <p>{userStats.lastOnline}</p>
        </div>
      </div>
    </div>
  );
}

export default UserStats;