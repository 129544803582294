import './index.css';
import './styles/App.css';
import * as React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import AI from './pages/AI.js';
import Login from './pages/LoginPage.js';
import Signup from './pages/SignUpPage.js'
import LandingPage from './pages/LandingPage.js'
import UserStats from './pages/UserStats.js'
import SettingsPage from './pages/SettingsPage.js'
import ProfilePage from './pages/ProfilePage.js'
import PrivacyPolicy from './pages/PrivacyPolicy.js'
import TermsOfUse from './pages/TermsOfUse.js'
import AuthCallback from './pages/AuthCallback.js'
import FactCheckPage from './pages/FactCheckPage.js'
import SelfAI from './pages/self_debate/SelfAI.js'
import StoredDebate from './pages/StoredDebate.js'
import BPAi from './pages/BPAi.js'
import RoomsHome from './pages/RoomsHome.js';
import DebateRoom from './pages/DebateRoom.js';
import { getProfileData, getUsernameExists, updateUsername } from "./queries/Queries.js";
import { useState, useEffect } from "react";
import { AlertTriangle } from 'lucide-react';

const UsernameValidationModal = ({ oldUsername }) => {
  const [newUsername, setNewUsername] = useState('');
  const [error, setError] = useState('');
  const [isChecking, setIsChecking] = useState(false);

  const validateUsername = async (username) => {
    if (!username) {
      return "Username is required";
    }

    const validationRules = [
      {
        test: (value) => value.length <= 30,
        message: "Username must be 30 characters or less"
      },
      {
        test: (value) => /^[a-zA-Z0-9._]*$/.test(value),
        message: "Username can only contain letters, numbers, periods, and underscores"
      },
      {
        test: (value) => !/[!@#$%^&*()+\-=[\]{};':"\\|,<>/?~`]/.test(value),
        message: "Username cannot contain symbols or punctuation marks"
      },
      {
        test: (value) => !/[\u{1F300}-\u{1F9FF}]/u.test(value),
        message: "Username cannot contain emojis"
      }
    ];

    for (const rule of validationRules) {
      if (!rule.test(username)) {
        return rule.message;
      }
    }

    // Check if username is taken
    const exists = await getUsernameExists(username);
    if (exists) {
      return "This username is already taken";
    }

    return null;
  };

  const handleSubmit = async () => {
    setIsChecking(true);
    setError('');

    try {
      const validationError = await validateUsername(newUsername);
      
      if (validationError) {
        setError(validationError);
        return;
      }

      const success = await updateUsername(newUsername);
      if (success) {
        window.location.href = '/settings';
      } else {
        setError('Failed to update username. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsChecking(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-black border border-gray-800 rounded-xl max-w-md w-full p-6 shadow-lg">
        <div className="flex items-center gap-3 mb-4">
          {oldUsername ? (
            <>
              <AlertTriangle className="text-yellow-500" size={24} />
              <h2 className="text-xl font-bold text-white">Quick Username Update Needed</h2>
            </>
          ) : (
            <h2 className="text-xl font-bold text-white">Just one more thing...</h2>
          )}
        </div>
        
        <div className="space-y-4">
          <div className="space-y-2">
            <p className="text-gray-300">
              {oldUsername 
                ? "We've recently updated our username guidelines to ensure a better experience for everyone."
                : "To get started, choose a username for your account."}
            </p>
            
            {oldUsername && (
              <div className="bg-gray-900 border border-gray-700 rounded-lg p-4">
                <p className="text-sm text-gray-400">Your current username</p>
                <p className="text-white font-medium">{oldUsername}</p>
              </div>
            )}
          </div>

          <div className="space-y-2">
            <label htmlFor="username" className="text-sm text-gray-400">
              Choose a new username
            </label>
            <input
              id="username"
              type="text"
              value={newUsername}
              onChange={(e) => {
                setNewUsername(e.target.value);
                setError('');
              }}
              className="w-full bg-gray-900 border border-gray-700 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-green-600 transition-colors"
              placeholder="Enter new username"
            />
            {error && (
              <p className="text-sm text-red-400">{error}</p>
            )}
          </div>

          <div className="mt-6">
            <button
              onClick={handleSubmit}
              disabled={isChecking}
              className="w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-3 px-4 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isChecking ? 'Checking...' : 'Choose Username'}
            </button>
          </div>

          <div className="bg-gray-900/50 rounded-lg p-4 space-y-2">
            <p className="text-sm text-gray-400 font-medium">Username Guidelines:</p>
            <ul className="text-sm text-gray-400 list-disc list-inside space-y-1">
              <li>30 characters maximum</li>
              <li>Letters, numbers, periods, and underscores only</li>
              <li>No special characters or emojis</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

function App() {
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const validateUsername = (username) => {
    if (username === undefined || username === null) {
      return "Choose a username!";
    }

    const validationRules = [
      {
        test: (value) => value.length <= 30,
        message: "Username must be 30 characters or less"
      },
      {
        test: (value) => /^[a-zA-Z0-9._]*$/.test(value),
        message: "Username can only contain letters, numbers, periods, and underscores"
      },
      {
        test: (value) => !/[!@#$%^&*()+\-=[\]{};':"\\|,<>/?~`]/.test(value),
        message: "Username cannot contain symbols or punctuation marks"
      },
      {
        test: (value) => !/[\u{1F300}-\u{1F9FF}]/u.test(value),
        message: "Username cannot contain emojis"
      }
    ];

    for (const rule of validationRules) {
      if (!rule.test(username)) {
        return rule.message;
      }
    }
    return null;
  };

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      try {
        const result = await getProfileData();
        setUserAndProfile(result);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserAndProfile();
  }, []);

  // Show nothing while loading
  if (isLoading) {
    return null;
  }

  const validationMessage = validateUsername(userAndProfile?.username);



  if (validationMessage === null || userAndProfile === null) {
    console.log("Showing modal with:", {
      validationMessage,
      userAndProfile
    });
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/ai" element={<AI />}></Route> 
          <Route path="/userstats" element={<UserStats />}></Route>
          <Route path="/settings" element={<SettingsPage />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
          <Route path="/auth/callback" element={<AuthCallback />}></Route>
          <Route path="/profile" element={<ProfilePage />}></Route>
          <Route path="/fact-check" element={<FactCheckPage />} />
          <Route path="/self-ai" element={<SelfAI />} />
          <Route path="/stored-debates/:debateId" element={<StoredDebate />} />
          <Route path="/bpai" element={<BPAi />}/>
          <Route path="/rooms" element={<RoomsHome />} />
          <Route path="/debate/:roomId" element={<DebateRoom />} />
        </Routes>
      </BrowserRouter>
    );
  } else {
    console.log("Showing modal with:", {
      validationMessage,
      userAndProfile
    });
    return (
      <UsernameValidationModal
        oldUsername={userAndProfile?.username}
        validationMessage={validationMessage}
      />
    );
  }
}

export default App;