import React from 'react';
import TopMenuBar from "../components/TopMenuBar";
import styles from "../styles/PrivacyPolicy.module.css";

const PrivacyPolicy = ({ userAndProfile, onSignOut }) => {
  return (
    <div className={styles.privacyPolicyPage}>
      <div className={styles.content}>
        <h1>Privacy Policy</h1>
        <p>Last updated: 08/17/2024</p>

        <section>
          <h2>1. Data We Collect</h2>
          <p>In the course of using our debate app, we collect and process the following information about your debates:</p>
          <ul>
            <li>Debate topics</li>
            <li>Setup panel selections (Position, Role, and Persona)</li>
            <li>Messages exchanged during each debate</li>
          </ul>
        </section>

        <section>
          <h2>2. How We Use Your Data</h2>
          <p>We use this information to:</p>
          <ul>
            <li>Tailor a more personalized experience for each debater</li>
            <li>Attract more online users</li>
            <li>Improve our app's functionality and user experience</li>
          </ul>
          <p>We collect and store chat messages from debates on our platform. These messages are used for advertising purposes and to improve our service, helping us attract more users. While we use this data internally, we do not publish user names alongside chat messages, maintaining user anonymity in public contexts. Users should be aware that participating in debates constitutes consent for this data usage.</p>
        </section>

        <section>
          <h2>3. Data Storage and Security</h2>
          <ul>
            <li>All debate data is stored securely on servers provided by Supabase.</li>
            <li>Sensitive debate topics (e.g., politics, religion) are stored on a separate database for enhanced protection.</li>
          </ul>
        </section>

        <section>
          <h2>4. Data Retention</h2>
          <p>We retain your debate performance data for 5 years from the date of creation.</p>
        </section>

        <section>
          <h2>5. Your Rights and Data Access</h2>
          <ul>
            <li>Premium users can access and delete their own debate data.</li>
            <li>If you wish to exercise these rights, please upgrade to a premium account.</li>
            <li>To request data deletion, please contact us at [your contact email].</li>
          </ul>
        </section>

        <section>
          <h2>6. Limitations on Data Rights</h2>
          <p>Access to personal debate data is a premium feature.</p>
        </section>

        <section>
          <h2>7. Data Sharing</h2>
          <p>We do not sell your debate data. We may use anonymized, aggregated data for research or to improve our services.</p>
        </section>

        <section>
          <h2>8. Minors' Data</h2>
          <ul>
            <li>This app is not intended for users under the age fo 13</li>
            <li>If we learn that we have collected personal information from a minor, we will promptly delete it.</li>
          </ul>
        </section>

        <section>
          <h2>9. Consent for Additional Use</h2>
          <p>If we intend to use your data for purposes beyond the app's core functionality (e.g., academic research), we will seek your explicit consent beforehand.</p>
        </section>

        <section>
          <h2>10. Google Account Integration</h2>
          <p>We offer the option to sign in to our app using Google Sign-In.
            When you choose to sign in with Google, we only request access to your email address.
            We use your email address solely for the following purposes:

            To create and authenticate your account within our app
            To automatically generate a username for you in our system
            To send important account-related notifications


            We do not request or access any other information from your Google account.
            We do not use your email address for marketing purposes or share it with third parties.
            Your email address is stored securely on our servers, consistent with our data storage practices outlined in Section 3 of this policy.
            You can revoke our app's access to your Google account at any time through your Google Account settings.
            If you choose to delete your account with us, we will also remove the association with your Google account email.</p>
        </section>
            
        <section>
          <h2>11. Changes to This Policy</h2>
          <p>We may update this privacy policy from time to time. We will notify users of any changes by email or in-app notification.</p>
        </section>

        <section>
          <h2>12. Contact Us</h2>
          <p>If you have any questions about this privacy policy or our data practices, please contact us at info@debatearena.app.</p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;