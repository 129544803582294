import React, { useState, useEffect, useRef } from 'react';
import { FaBold, FaItalic, FaUnderline, FaIndent, FaListUl } from 'react-icons/fa';
import styles from '../styles/BPPrep.module.css';

const BPPrep = ({ prep, setPrep, prepTimeLeft, motion, side, bpInfo, setShowPrep, handleEndPrep }) => {
    const [activeFormats, setActiveFormats] = useState({});
    const [currentBlockFormat, setCurrentBlockFormat] = useState('p');
    const [timeLeft, setTimeLeft] = useState(15 * 60);
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const editorRef = useRef(null);
    const popupRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowInfoPopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleContentChange = () => {
    if (editorRef.current) {
      const newContent = editorRef.current.innerHTML;
      console.log('Content changed:', newContent); // Debug log
      setPrep(newContent);
      updateActiveFormats();
    }
  };

  const toggleInfoPopup = () => {
    setShowInfoPopup(!showInfoPopup);
  };

  const applyFormat = (command) => {
    document.execCommand(command, false, null);
    handleContentChange();
  };

  const indent = () => {
    document.execCommand('indent', false, null);
    handleContentChange();
  };

  const createBulletList = () => {
    document.execCommand('insertUnorderedList', false, null);
    handleContentChange();
  };

  const changeBlockFormat = (format) => {
    document.execCommand('formatBlock', false, format);
    setCurrentBlockFormat(format);
    handleContentChange();
  };

  const updateActiveFormats = () => {
    setActiveFormats({
      bold: document.queryCommandState('bold'),
      italic: document.queryCommandState('italic'),
      underline: document.queryCommandState('underline'),
      bulletList: document.queryCommandState('insertUnorderedList'),
    });

    const blockFormat = document.queryCommandValue('formatBlock');
    setCurrentBlockFormat(blockFormat || 'p');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      indent();
    }
    console.log('Key pressed:', e.key); // Debug log
  };

  /** const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }; **/

  const handleFinishPrep = () => {
    // Implement finish prep logic here
    setShowPrep(false);
    handleEndPrep();
  };

  const getRoleInfo = () => {
    if (side === 'gov') {
        return { text: 'gov. (for)', className: styles.govRole };
    } else {
        return { text: 'opp. (against)', className: styles.oppRole };
    }
  };

  const roleInfo = getRoleInfo();

  const formatTime = (time) => {
    if (typeof time !== 'number' || isNaN(time)) {
        console.error('Invalid time value:', time);
        return '00:00';
    }
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };


  return (
    <div className={styles.container}>
      <div className={`${styles.header} ${isMobile ? styles.headerMobile : ''}`}>
        <div className={styles.timerContainer}>
          <span className={styles.timer}>{formatTime(prepTimeLeft)}</span>
        </div>
        <span className={styles.roleInfo}>you are <span className={`${styles.role} ${roleInfo.className}`}>{roleInfo.text}</span></span>
        <div className={styles.finishPrepContainer}>
          <button className={styles.finishPrepButton} onClick={handleFinishPrep}>
            {isMobile ? 'Finish' : 'Finish Prep'}
          </button>
        </div>
      </div>
      <div className={styles.motionContainer}>
        <button className={styles.infoButton} onClick={toggleInfoPopup} aria-label="Toggle Info">
          <div className={styles.infoTriangle} />
          <span className={styles.infoText}> info</span>
        </button>
        <h1 className={`${styles.motion} ${isMobile ? styles.motionMobile : ''}`}>
          {motion}
        </h1>
      </div>
      {showInfoPopup && (
        <div className={`${styles.infoPopup} ${showInfoPopup ? styles.active : ''}`}>
          <div className={styles.infoPopupContent}>
            <h2>INFO</h2>
            <ul className={styles.infoBullets}>
                {bpInfo.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
            <button className={styles.closeInfoButton} onClick={toggleInfoPopup}>
              {isMobile ? <div className={styles.infoTriangleClose} /> : 'X'}
            </button>
          </div>
        </div>
      )}
      <div className={styles.editorContainer}>
        <div className={styles.formatBar}>
          <select 
            className={styles.formatSelect}
            value={currentBlockFormat}
            onChange={(e) => changeBlockFormat(e.target.value)}
          >
            <option value="p">Paragraph</option>
            <option value="h1">Header 1</option>
            <option value="h2">Header 2</option>
            <option value="h3">Header 3</option>
          </select>
          <button 
            className={`${styles.formatButton} ${activeFormats.bold ? styles.active : ''}`} 
            onClick={() => applyFormat('bold')}
          >
            <FaBold />
          </button>
          <button 
            className={`${styles.formatButton} ${activeFormats.italic ? styles.active : ''}`} 
            onClick={() => applyFormat('italic')}
          >
            <FaItalic />
          </button>
          <button 
            className={`${styles.formatButton} ${activeFormats.underline ? styles.active : ''}`} 
            onClick={() => applyFormat('underline')}
          >
            <FaUnderline />
          </button>
          <button className={styles.formatButton} onClick={indent}><FaIndent /></button>
          <button 
            className={`${styles.formatButton} ${activeFormats.bulletList ? styles.active : ''}`} 
            onClick={createBulletList}
          >
            <FaListUl />
          </button>
        </div>
        <div
          ref={editorRef}
          className={`${styles.editor} ${isMobile ? styles.editorMobile : ''}`}
          contentEditable
          onInput={handleContentChange}
          onKeyDown={handleKeyDown}
          onMouseUp={updateActiveFormats}
        />
      </div>
    </div>
  );
};

export default BPPrep;