import { createClient } from '@supabase/supabase-js'


//console.log('SUPABASE_URL:', process.env.REACT_APP_SUPABASE_URL);
//console.log('SUPABASE_API_KEY:', process.env.REACT_APP_SUPABASE_API_KEY);

export const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_API_KEY, {
auth: {
  redirectTo: 'http://localhost:3000/auth/callback'
}
})

export const handleLogin = async (email, password) => {
    try {
        const { data, error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });
    
        if (error) throw error;
    
        // JWT is automatically stored by Supabase client
        // Instead of using window.location.href, consider using React Router
        // navigate('/dashboard');
        
        return data; // Return the data for further processing if needed
      } catch (error) {
        console.error('Error logging in:', error.message);
        // Consider returning the error or a boolean to indicate failure
        return false;
      }
}


