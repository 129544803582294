import React, { useState } from 'react';
import { X } from 'lucide-react';
import styles from '../styles/SetupDebateRoom.module.css';
import { createDebateRoom } from '../queries/roomQueries';
import { generateDebateTopic } from '../utils/api';

const SetupDebateRoom = ({ isOpen, onClose, onRoomCreated }) => {
  const [topic, setTopic] = useState('');
  const [side, setSide] = useState('Random');
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);

  const handleAutoSelectTopic = async () => {
    try {
      setError(null);
      const newTopic = await generateDebateTopic();
      setTopic(newTopic.replace(/["]+/g, "").toLowerCase());
    } catch (error) {
      console.error("Error generating topic:", error);
      setTopic("universal basic income");
      setError("Failed to generate topic. Using default.");
    }
  };

  const handleTopicChange = (e) => {
    setError(null);
    // Capitalize first letter of each sentence
    const text = e.target.value.replace(/(^\w|\.\s+\w)/g, letter => letter.toUpperCase());
    setTopic(text);
  };

  const handleCreateRoom = async () => {
    if (!topic.trim()) {
      setError('Please enter a topic to start the debate.');
      return;
    }
  
    setIsCreating(true);
    setError(null);
  
    try {
      const { data, error } = await createDebateRoom(topic, side);
      
      if (error) {
        setError(error);
        return;
      }
  
      if (data) {
        // Open new tab with debate room URL
        window.open(`/debate/${data.id}`, '_blank');
        onRoomCreated?.(data);
        handleClose();
      } else {
        setError('Failed to create room. Please try again.');
      }
    } catch (error) {
      console.error('Error creating room:', error);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setIsCreating(false);
    }
  };

  const handleClose = () => {
    setTopic('');
    setSide('Random');
    setError(null);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.setupPanel}>
        <button className={styles.closeButton} onClick={handleClose}>
          <X size={24} />
        </button>
        
        <div className={styles.panelContent}>
          <h2 className={styles.setupHeading}>Create Debate Room</h2>

          {error && (
            <div className={styles.errorMessage}>
              {error}
            </div>
          )}

          <div className={styles.section}>
            <h3>Topic</h3>
            <div className={styles.topicInputContainer}>
              <input
                type="text"
                value={topic}
                onChange={handleTopicChange}
                placeholder="Enter Topic (a Binary Question / Stance)"
                className={styles.input}
                disabled={isCreating}
              />
              <span 
                className={`${styles.autoSelect} ${isCreating ? styles.disabled : ''}`}
                onClick={() => !isCreating && handleAutoSelectTopic()}
              >
                Auto
              </span>
            </div>
          </div>

          <hr className={styles.divider} />

          <div className={styles.section}>
            <div className={styles.formatInfo}>
              <p>Standard Format: 5 rounds of back-and-forth debate</p>
              <p>Each participant will have 3 minutes per round to present their arguments</p>
            </div>
          </div>

          <hr className={styles.divider} />

          <div className={styles.section}>
            <h3>Choose Your Side</h3>
            <div className={styles.sideButtons}>
              <button
                className={`${styles.sideButton} ${styles.forButton} ${
                  side === "For" ? styles.active : ""
                }`}
                onClick={() => setSide("For")}
                disabled={isCreating}
              >
                For
              </button>
              <button
                className={`${styles.sideButton} ${styles.randomButton} ${
                  side === "Random" ? styles.active : ""
                }`}
                onClick={() => setSide("Random")}
                disabled={isCreating}
              >
                Random
              </button>
              <button
                className={`${styles.sideButton} ${styles.againstButton} ${
                  side === "Against" ? styles.active : ""
                }`}
                onClick={() => setSide("Against")}
                disabled={isCreating}
              >
                Against
              </button>
            </div>
          </div>

          <div className={styles.startButtonContainer}>
            <button
              className={`${styles.startButton} ${isCreating ? styles.loading : ''}`}
              onClick={handleCreateRoom}
              disabled={!topic.trim() || isCreating}
            >
              {isCreating ? 'Creating Room...' : 'Create Room'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupDebateRoom;