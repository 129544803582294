import React from 'react';
import styles from '../styles/AI.module.css';

const BadResponse = ({ unacceptable, invalidated, attemptsLeft, onClose }) => {
    return (
      <div className={styles.cardOverlay}>
        {
            unacceptable && 
            <div className={styles.cardContent}>
             <button className={styles.closeButton} onClick={onClose}>×</button>
             <p>please do not try to break the llm!</p>
             <p>if you are having an emergency, please take immediate action:
                call 911 for urgent medical, fire, or police assistance.
                national suicide prevention lifeline: 1-800-273-8255 (24/7 support).
                crisis text line: text home to 741741 for free, 24/7 crisis support.</p>
           </div>
        }
        {
            !unacceptable && invalidated && 
            <div className={styles.cardContent}>
             <button className={styles.closeButton} onClick={onClose}>×</button>
             <p>please stay on topic.</p>
             <p>if you input {(3 - attemptsLeft).toString()} more unrelated responses, you will automatically forfeit this debate. try again.</p>
             <p>invalid attempt {(attemptsLeft).toString()}/3</p>
           </div>
        }
        { !unacceptable && (attemptsLeft !== 3 && !invalidated) && 
             <div className={styles.cardContent}>
             <button className={styles.closeButton} onClick={onClose}>×</button>
             <p>please stay on topic.</p>
             <p>if you input {(3 - attemptsLeft).toString()} more unrelated responses, you will automatically forfeit this debate. try again.</p>
             <p>invalid attempt {(attemptsLeft).toString()}/3</p>
           </div> }
        {  !unacceptable && (attemptsLeft === 3 && !invalidated) &&
            <div className={styles.cardContent}>
            <button className={styles.closeButton} onClick={onClose}>×</button>
            <p>you have been removed from this session.</p>
            <p>please stay on topic next debate, you will be removed again for getting off track.</p>
          </div> }
      </div>
    );
  };

export default BadResponse;