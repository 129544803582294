import React, { useState, useEffect } from 'react';
import { Play, Pause, Loader } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import styles from "../styles/MessageComponent.module.css";

const Message = ({ content, role, ttsHandler }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isTtsReady, setIsTtsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);

  useEffect(() => {
    if (role.startsWith('assistant') && content && !content.endsWith('...')) {
      setIsTtsReady(true);
    }
    
    // Clean up on unmount
    return () => {
      if (isPlaying) {
        ttsHandler.stop();
      }
    };
  }, [content, role]);

  const handlePlayPause = async () => {
    if (isPlaying) {
      ttsHandler.togglePlayPause();
      setIsPlaying(false);
    } else {
      setIsLoading(true);
      const success = await ttsHandler.queueText(content, playbackSpeed);
      setIsLoading(false);
      
      if (success) {
        setIsPlaying(true);
      }
    }
  };

  const handleSpeedChange = (event) => {
    const newSpeed = parseFloat(event.target.value);
    setPlaybackSpeed(newSpeed);
    if (isPlaying) {
      ttsHandler.setPlaybackSpeed(newSpeed);
    }
  };

  const messageClassName = `${styles.message} ${styles[`message-${role}`]}`;

  return (
    <div className={messageClassName}>
      <ReactMarkdown
        className={styles.content}
        components={{
          p: ({ node, ...props }) => (
            <p className={styles.paragraph} {...props} />
          ),
          ul: ({ node, ...props }) => (
            <ul className={styles.unorderedList} {...props} />
          ),
          ol: ({ node, ...props }) => (
            <ol className={styles.orderedList} {...props} />
          ),
          li: ({ node, ...props }) => (
            <li className={styles.listItem} {...props} />
          ),
        }}
      >
        {content}
      </ReactMarkdown>
      {isTtsReady && (
        <div className={styles.ttsControls}>
          <button 
            onClick={handlePlayPause}
            className={styles.ttsButton}
            disabled={isLoading}
            aria-label={isPlaying ? 'Pause speech' : 'Play speech'}
          >
            {isLoading ? (
              <Loader className={styles.loadingIcon} size={14} />
            ) : isPlaying ? (
              <Pause size={14} />
            ) : (
              <Play size={14} />
            )}
          </button>
          <select
            className={styles.speedControl}
            value={playbackSpeed}
            onChange={handleSpeedChange}
            disabled={isLoading}
          >
            <option value="1">1x</option>
            <option value="1.25">1.25x</option>
            <option value="1.5">1.5x</option>
          </select>
        </div>
      )}
    </div>
  );
};

export default Message;