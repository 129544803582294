export function extractClaim(text) {
    const claimRegex = /Claim:\s*([\s\S]*?)\s*\n\s*Warrant:/i;
    const match = text.match(claimRegex);
    
    if (match && match[1]) {
      return match[1].trim();
    }
    
    return null;
  }

  export function extractWarrant(text) {
    const warrantRegex = /Warrant:\s*([\s\S]*?)\s*\n\s*Impact:/i;
    const match = text.match(warrantRegex);
    
    if (match && match[1]) {
      return match[1].trim();
    }
    
    return null;
  }

  export function extractImpact(text) {
    const impactRegex = /Impact:\s*([\s\S]*?)(?:\s*$|\s*\n\s*$)/i;
    const match = text.match(impactRegex);
    
    if (match && match[1]) {
      return match[1].trim();
    }
    
    return null;
  }