import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopMenuBar from '../components/TopMenuBar';
import FactCheckResult from '../components/FactCheckResult';
import SignUpModal from '../components/SignUpModal';
import styles from '../styles/FactCheckPage.module.css';
import { preliminaryCheck, searchExaForFactCheck, evaluateSourcesWithLLM } from '../utils/api';
import { getCurrentUserAndProfile } from '../queries/Queries';

import factCheckImage from '../images/factcheck.png'; // Update this path to your actual image

const FactCheckPage = () => {
  const [claim, setClaim] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const navigate = useNavigate();

  const handleSignOut = () => {
    setUserAndProfile(null);
    navigate("/")
  };

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  const handleCheck = async () => {
    if (!claim.trim()) return;
    
    if (!userAndProfile) {
      setShowSignUpModal(true);
      return;
    }

    setIsLoading(true);
    setError(null);
    setResult(null);
  
    try {
      // Step 1: Preliminary check
      const { isBasicFact, answer, isVague } = await preliminaryCheck(claim);
  
      if (isVague) {
        setError("please write your fact more explicitly");
        return;
      }
  
      if (isBasicFact && answer !== null) {
        // For basic facts, use the LLM's answer
        setResult({
          claim,
          confidenceScore: '100',
          isTrue: answer === 'true',
          sources: [],
          isBasicFact: true
        });
      } else {
        // Step 2: Search Exa for sources
        const sources = await searchExaForFactCheck(claim, answer);

        if (sources.length === 0) {
          throw new Error("No relevant sources found for the given claim.");
        }

        // Step 3: Evaluate sources with LLM
        const evaluations = await evaluateSourcesWithLLM(claim, sources);

        // Step 4: Calculate confidence score based on source relevance
        const totalRelevance = evaluations.reduce((sum, evalx) => sum + Math.abs(evalx.score), 0);
        const weightedScore = evaluations.reduce((sum, evalx) => sum + evalx.score, 0);
        const normalizedScore = weightedScore / totalRelevance; // Range: -1 to 1
        const isTrue = normalizedScore > 0;
        const confidenceScore = Math.abs(normalizedScore) * 100;

        setResult({
          claim,
          confidenceScore: confidenceScore.toFixed(2),
          isTrue,
          sources: sources.map((source, index) => ({
            ...source,
            score: evaluations[index]?.score || 0
          })).filter(source => source.score !== 0),
          isBasicFact: false
        });
      }
    } catch (error) {
      console.error("Error during fact-checking:", error);
      setError(error.message || "An error occurred while fact-checking. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleCheck();
    }
  };

  return (
    <div className={styles.factCheckPage}>
      <TopMenuBar userAndProfile={userAndProfile} onSignOut={handleSignOut} />
      <div className={`${styles.content} ${showSignUpModal ? styles.blurred : ''}`}>
        <h1>ai fact checker with sources</h1>
        <p className={styles.disclaimer}>
          disclaimer: this feature is in beta and should not be solely relied upon for critical decision-making. 
          always verify information from multiple trusted sources.
        </p>
        <div className={styles.inputContainer}>
          <textarea
            value={claim}
            onChange={(e) => setClaim(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="enter the claim you want to fact-check..."
            className={styles.input}
            rows={1}
          />
          <button onClick={handleCheck} className={styles.button} disabled={isLoading || !claim.trim()}>
            {isLoading ? 'checking...' : 'verify'}
          </button>
        </div>
        {error && <p className={styles.error}>{error.toLowerCase()}</p>}
        {result ? (
          <FactCheckResult result={result} />
        ) : (
          <div className={styles.imageContainer}>
            <img src={factCheckImage} alt="Fact Check Illustration" className={styles.factCheckImage} />
          </div>
        )}
      </div>
      {showSignUpModal && (
        <SignUpModal onClose={() => setShowSignUpModal(false)} />
      )}
    </div>
  );
};

export default FactCheckPage;