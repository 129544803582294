import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

const DiscordPopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasBeenShown = sessionStorage.getItem('discordPopupShown');
    
    if (!hasBeenShown) {
      setIsVisible(true);
      sessionStorage.setItem('discordPopupShown', 'true');
    }
  }, []);

  if (!isVisible) return null;

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <div className="fixed inset-0 z-50">
      <div 
        className="absolute inset-0 bg-black/70 backdrop-blur-sm"
        onClick={handleClose}
      />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="relative w-full max-w-2xl bg-black border border-blue-500 rounded-xl shadow-lg p-8">
          <div 
            className="absolute top-4 right-4 text-gray-400 cursor-pointer"
            onClick={handleClose}
          >
            <X size={28} />
          </div>
          
          <div className="flex items-center flex-col text-center">
            <div className="mb-6">
              <svg 
                className="w-12 h-12 text-blue-500"
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 127.14 96.36"
                fill="currentColor"
              >
                <path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/>
              </svg>
            </div>
            
            <h3 className="text-white font-semibold text-2xl mb-3">Join our Discord Community!</h3>
            <p className="text-gray-300 text-lg mb-5">
              Shape the future of debate! Join our vibrant community to discuss features, share strategies, and connect with fellow debaters.
            </p>
            
            <a 
              href="https://discord.gg/QWJz2MN5MV"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-lg text-lg font-medium transition-colors"
            >
              Join Discord
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscordPopup;