import React, { useState, useEffect, useRef } from "react";
import { modes } from "../constants/debateModes";
import { personas } from "../constants/personas";
import { useNavigate } from "react-router-dom";
import { generateDebateTopic } from "../utils/api";
import { getCurrentUserAndProfile } from "../queries/Queries";
import { Lock } from "lucide-react";
import styles from "../styles/SetupPanelAI.module.css";

const SetupPanel = ({
  mode,
  setMode,
  topic,
  setTopic,
  examSide,
  setExamSide,
  side,
  setSide,
  debateStarted,
  handleStartDebate,
  setSelectedPersona,
}) => {
  const [trendingTopics, setTrendingTopics] = useState([
    "primary education should be free for everyone",
    "succession is the greatest show of all time",
    "lebron james is the basketball goat",
    "the death penalty should be abolished",
  ]);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [currentTrendingIndex, setCurrentTrendingIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedPersonaId, setSelectedPersonaId] = useState(1);
  const animationTimeoutRef = useRef(null);
  const trendingTopicRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  const handleTrendingTopicClick = () => {
    setTopic(trendingTopics[currentTrendingIndex]);
  };

  useEffect(() => {
    const animationDuration = 3000;
    const halfAnimationDuration = animationDuration / 2;

    const animate = () => {
      setIsAnimating(true);

      // Clear any existing timeouts
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }

      // Set timeout to change topic halfway through the animation
      animationTimeoutRef.current = setTimeout(() => {
        setCurrentTrendingIndex(
          (prevIndex) => (prevIndex + 1) % trendingTopics.length
        );
        setIsAnimating(false);
      }, halfAnimationDuration);
    };

    const interval = setInterval(animate, animationDuration);

    return () => {
      clearInterval(interval);
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, [trendingTopics.length]);

  const handleAutoSelectTopic = async () => {
    try {
      const newTopic = await generateDebateTopic();
      setTopic(newTopic.replace(/["]+/g, "").toLowerCase());
    } catch (error) {
      console.error("Error generating topic:", error);
      setTopic("universal basic income"); // some default
    }
  };

  const sortedPersonas = [...personas].sort((a, b) => {
    const difficultyOrder = { easy: 1, moderate: 2, hard: 3 };
    return difficultyOrder[a.difficulty] - difficultyOrder[b.difficulty];
  });

  const personasPerPage = 4;
  const totalPages = Math.ceil(sortedPersonas.length / personasPerPage);

  const handlePersonaSelect = (persona) => {
    if (
      !userAndProfile &&
      (persona.difficulty === "moderate" || persona.difficulty === "hard")
    ) {
      navigate("/signup");
    } else {
      setSelectedPersona(persona);
      setSelectedPersonaId(persona.id);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  const renderContent = () => {
    if ((mode === "Standard" || mode === "Cross-Examine") && !userAndProfile) {
      return (
        <div className={styles.signupPrompt}>
          <button
            onClick={() => navigate("/signup")}
            className={styles.startButton}
          >
            sign up to access
          </button>
          <p>
            already have an account?{" "}
            <span
              onClick={() => navigate("/login")}
              className={styles.loginLink}
            >
              login
            </span>
          </p>
        </div>
      );
    }

    return (
      <>
        <div className={styles.section}>
          <h3>topic</h3>
          <div className={styles.topicInputContainer}>
            <input
              type="text"
              value={topic}
              onChange={(e) => setTopic(e.target.value.toLowerCase())}
              placeholder="enter topic (a binary question / stance)"
              className={styles.input}
              disabled={debateStarted}
            />
            <span className={styles.autoSelect} onClick={handleAutoSelectTopic}>
              auto
            </span>
          </div>
          <div className={styles.trending}>
            <span className={styles.trendingLabel}>trending 🔥</span>
            <div
              ref={trendingTopicRef}
              className={`${styles.trendingTopic} ${
                isAnimating ? styles.animating : ""
              }`}
              onClick={handleTrendingTopicClick}
            >
              {trendingTopics[currentTrendingIndex]}
            </div>
          </div>
        </div>

        <hr className={styles.divider} />

        {mode === "Cross-Examine" && (
          <div className={styles.section}>
            <h3>exam role</h3>
            <div className={styles.examineToggleButtons}>
              <button
                className={`${styles.examButton} ${styles.examinerButton} ${
                  examSide === "Examiner" ? styles.active : ""
                }`}
                onClick={() => !debateStarted && setExamSide("Examiner")}
                disabled={debateStarted}
              >
                examiner
              </button>
              <button
                className={`${styles.examButton} ${styles.examineeButton} ${
                  examSide === "Examinee" ? styles.active : ""
                }`}
                onClick={() => !debateStarted && setExamSide("Examinee")}
                disabled={debateStarted}
              >
                examinee
              </button>
            </div>
          </div>
        )}

        {mode === "Blitz" && (
          <div className={styles.section}>
            <h3>opponent</h3>
            <div className={styles.personaGrid}>
              {sortedPersonas
                .slice(
                  currentPage * personasPerPage,
                  (currentPage + 1) * personasPerPage
                )
                .map((persona) => (
                  <div
                    key={persona.id}
                    className={`${styles.personaCell} ${
                      persona.id === selectedPersonaId ? styles.selected : ""
                    } ${
                      !userAndProfile &&
                      (persona.difficulty === "moderate" ||
                        persona.difficulty === "hard")
                        ? styles.locked
                        : ""
                    }`}
                    onClick={() => handlePersonaSelect(persona)}
                  >
                    <div className={styles.personaImageContainer}>
                      <img
                        src={persona.avatar}
                        alt={persona.name}
                        className={styles.personaAvatar}
                      />
                      {!userAndProfile &&
                        (persona.difficulty === "moderate" ||
                          persona.difficulty === "hard") && (
                          <div className={styles.lockOverlay}>
                            <Lock size={24} />
                          </div>
                        )}
                    </div>
                    <span className={styles.personaName}>{persona.name}</span>
                    <span
                      className={`${styles.personaDifficulty} ${
                        styles[persona.difficulty]
                      }`}
                    >
                      {persona.difficulty}
                    </span>
                  </div>
                ))}
            </div>
            <div className={styles.paginationControls}>
              <button onClick={handlePrevPage} disabled={currentPage === 0}>
                prev
              </button>
              <span>{`${currentPage + 1} / ${totalPages}`}</span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages - 1}
              >
                next
              </button>
            </div>
          </div>
        )}

        {(mode === "Cross-Examine" || mode === "Blitz") && (
          <hr className={styles.divider} />
        )}

        <div className={styles.section}>
          <h3>choose your side</h3>
          <div className={styles.sideButtons}>
            <button
              className={`${styles.sideButton} ${styles.forButton} ${
                side === "for" ? styles.active : ""
              }`}
              onClick={() => !debateStarted && setSide("for")}
              disabled={debateStarted}
            >
              for
            </button>
            <button
              className={`${styles.sideButton} ${styles.againstButton} ${
                side === "Against" ? styles.active : ""
              }`}
              onClick={() => !debateStarted && setSide("against")}
              disabled={debateStarted}
            >
              against
            </button>
          </div>
        </div>

        <div className={styles.startButtonContainer}>
          <button
            className={styles.startButton}
            onClick={handleStartDebate}
            disabled={debateStarted}
          >
            start debate
          </button>
        </div>
      </>
    );
  };

  return (
    <div className={styles.setupPanelWrapper}>
      <div className={styles.setupPanel}>
        {/* <h2 className={styles.setupHeading}>set up your ai debate</h2> */}
        <div className={styles.panelContent}>
          <div className={styles.section}>
            <h3>gamemode</h3>
            <select
              className={styles.input}
              value={mode}
              onChange={(e) => setMode(e.target.value)}
              disabled={debateStarted}
            >
              {Object.entries(modes).map(([modeName, modeData]) => (
                (modeName !== "BP") ?
                (<option key={modeName} value={modeName}>
                  {modeName.toLowerCase()}
                </option>) : (<></>)
              ))}
            </select>
          </div>

          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default SetupPanel;
