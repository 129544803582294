import React, { useState, useEffect, useRef } from 'react';
import { getProfileData, updateUsername, getCurrentUserAndProfile, updateProfilePicture, updateBio, getUsernameExists } from '../queries/Queries';
import styles from '../styles/SettingsPage.module.css';
import TopMenuBar from '../components/TopMenuBar';
import { Camera } from 'lucide-react';

const SettingsPage = () => {
  const [profileData, setProfileData] = useState(null);
  const [newUsername, setNewUsername] = useState('');
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [error, setError] = useState('');
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [bio, setBio] = useState('');
  const [isEditingBio, setIsEditingBio] = useState(false);
  const [newBio, setNewBio] = useState('');
  const [uploading, setUploading] = useState(false);
  const [usernameErrors, setUsernameErrors] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchProfileData();
    fetchUserAndProfile();
  }, []);

  const fetchProfileData = async () => {
    const data = await getProfileData();
    if (data) {
      setProfileData(data);
      setNewUsername(data.username || data.email);
      setBio(data.bio || 'Add a bio');
    }
  };

  const fetchUserAndProfile = async () => {
    const result = await getCurrentUserAndProfile();
    setUserAndProfile(result);
  };

  const validateUsername = async (username) => {
    const errors = [];
    
    // Length validation
    if (username.length > 30) {
      errors.push('Username must not exceed 30 characters');
    }

    // Characters validation (letters, numbers, periods, and underscores only)
    const validCharactersRegex = /^[a-zA-Z0-9._]*$/;
    if (!validCharactersRegex.test(username)) {
      errors.push('Username can only contain letters, numbers, periods, and underscores');
    }

    // Symbols validation (excluding allowed characters)
    const symbolsRegex = /[^a-zA-Z0-9._]/;
    if (symbolsRegex.test(username)) {
      errors.push('Username cannot contain symbols or punctuation marks');
    }

    // Emoji validation
    const emojiRegex = /[\u{1F000}-\u{1FFFF}]/u;
    if (emojiRegex.test(username)) {
      errors.push('Username cannot contain emojis');
    }

    // Check uniqueness if all other validations pass and username has changed
    if (errors.length === 0 && username !== profileData?.username) {
      const exists = await getUsernameExists(username);
      if (exists) {
        errors.push('Username is already taken');
      }
    }

    setUsernameErrors(errors);
    return errors.length === 0;
  };

  const handleImageUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        setError('Image size should be less than 5MB');
        return;
      }

      setUploading(true);
      setError('');

      const success = await updateProfilePicture(file);

      if (success) {
        await fetchProfileData();
        setError('');
      } else {
        setError('Failed to upload image. Please try again.');
      }
    } catch (error) {
      setError('An error occurred while uploading the image.');
      console.error('Upload error:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setNewUsername(value);
    validateUsername(value);
  };

  const handleUsernameSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (newUsername === profileData.username) {
      setIsEditingUsername(false);
      return;
    }

    const isValid = await validateUsername(newUsername);
    if (!isValid) {
      return;
    }

    const success = await updateUsername(newUsername);
    if (success) {
      await fetchProfileData();
      await fetchUserAndProfile();
      setIsEditingUsername(false);
      setUsernameErrors([]);
    } else {
      setError('Failed to update username. Please try again.');
    }
  };

  const handleBioClick = () => {
    setIsEditingBio(true);
    setNewBio(bio);
  };

  const handleBioChange = (e) => {
    setNewBio(e.target.value);
  };

  const handleBioSubmit = async () => {
    setError('');
    const success = await updateBio(newBio);
    if (success) {
      setBio(newBio);
      setIsEditingBio(false);
      await fetchProfileData();
    } else {
      setError('Failed to update bio. Please try again.');
    }
  };

  const handleBioCancel = () => {
    setIsEditingBio(false);
  };

  const handleSignOut = () => {
    setUserAndProfile(null);
  };

  if (!profileData) {
    return <div className={styles.loading}>Loading...</div>;
  }

  return (
    <div className={styles.pageContainer}>
      <TopMenuBar userAndProfile={userAndProfile} onSignOut={handleSignOut} />
      <div className={styles.profileContainer}>
        <div className={styles.profileBox}>
          <div className={styles.profileHeader}>
            <div 
              className={styles.profilePicture}
              onClick={handleUploadClick}
              style={{ cursor: 'pointer' }}
            >
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageUpload}
                accept="image/*"
                className="hidden"
                style={{ display: 'none' }}
              />
              {uploading ? (
                <div className={styles.uploadingOverlay}>
                  Uploading...
                </div>
              ) : (
                <>
                  <img 
                    src={profileData.avatar_url || "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"} 
                    alt="Profile" 
                    className={styles.profileImage}
                  />
                  <div className={styles.uploadOverlay}>
                    <Camera className="w-6 h-6" />
                    <span>Change Photo</span>
                  </div>
                </>
              )}
            </div>
            <div className={styles.profileNameStatus}>
              <h1 className={styles.profileName}>{profileData.username || profileData.email}</h1>
              <div className={styles.bioContainer} onClick={handleBioClick}>
                {isEditingBio ? (
                  <>
                    <input
                      type="text"
                      value={newBio}
                      onChange={handleBioChange}
                      className={styles.bioInput}
                    />
                    <div className={styles.bioButtons}>
                      <button onClick={handleBioSubmit} className={`${styles.bioButton} ${styles.saveBioButton}`}>
                        Save
                      </button>
                      <button onClick={handleBioCancel} className={`${styles.bioButton} ${styles.cancelBioButton}`}>
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <p className={styles.bioText}>{bio}</p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.profileInfo}>
            <div className={styles.field}>
              <label>Email</label>
              <p>{profileData.email}</p>
            </div>
            <div className={styles.field}>
              <label>Username</label>
              {isEditingUsername ? (
                <form onSubmit={handleUsernameSubmit} className={styles.editForm}>
                  <div className={styles.inputContainer}>
                    <input
                      type="text"
                      value={newUsername}
                      onChange={handleUsernameChange}
                      className={styles.usernameInput}
                    />
                    {usernameErrors.length > 0 && (
                      usernameErrors.map((error, index) => (
                        <p key={index} className={styles.error}>{error}</p>
                      ))
                    )}
                    <div className={styles.buttonContainer}>
                      <button type="submit" className={styles.saveButton}>Save</button>
                      <button 
                        onClick={() => {
                          setIsEditingUsername(false);
                          setUsernameErrors([]);
                          setNewUsername(profileData.username || '');
                        }} 
                        className={styles.cancelButton}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <p>
                  {profileData.username || 'Not set'}
                  <button 
                    onClick={() => setIsEditingUsername(true)} 
                    className={styles.editButton}
                  >
                    {profileData.username ? 'Edit' : 'Enter username'}
                  </button>
                </p>
              )}
            </div>
            <div className={styles.field}>
              <label>Debates Participated</label>
              <p>{profileData.debateCount}</p>
            </div>
          </div>
          {error && <p className={styles.error}>{error}</p>}
        </div>
        <div className={styles.deleteAccount}>
          Want to delete your account? Email <a href="mailto:debatehubs@gmail.com">debatehubs@gmail.com</a>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;