import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../styles/AuthPage.css";
import googleLogo from "../images/google_logo.png";
import { getUsernameExists, getEmailExists, handleSignUp, handleGoogleSignIn } from "../queries/Queries.js";

const UsernameInput = ({ value, onChange, error }) => {
  return (
    <div className="input-container">
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="username"
        required
      />
      {error && (
        <div className="validation-errors">
          <p className="error-message">{error}</p>
        </div>
      )}
    </div>
  );
};

const SignupPage = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({
    username: '',
    email: '',
    password: ''
  });
  const navigate = useNavigate();

  const validateUsername = (username) => {
    const validationRules = [
      {
        test: (value) => value.length <= 30,
        message: "Username must be 30 characters or less"
      },
      {
        test: (value) => /^[a-zA-Z0-9._]*$/.test(value),
        message: "Username can only contain letters, numbers, periods, and underscores"
      },
      {
        test: (value) => !/[!@#$%^&*()+\-=[\]{};':"\\|,<>/?~`]/.test(value),
        message: "Username cannot contain symbols or punctuation marks"
      },
      {
        test: (value) => !/[\u{1F300}-\u{1F9FF}]/u.test(value),
        message: "Username cannot contain emojis"
      }
    ];

    for (const rule of validationRules) {
      if (!rule.test(username)) {
        return rule.message;
      }
    }
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Reset all errors
    setErrors({
      username: '',
      email: '',
      password: ''
    });

    // Validate all fields
    let hasErrors = false;

    // Check passwords match
    if (password !== confirmPassword) {
      setErrors(prev => ({
        ...prev,
        password: "Passwords do not match"
      }));
      hasErrors = true;
    }

    // Validate username format
    const usernameError = validateUsername(username);
    if (usernameError) {
      setErrors(prev => ({
        ...prev,
        username: usernameError
      }));
      hasErrors = true;
    }

    try {
      // Only check existence if format validation passed
      if (!usernameError) {
        const usernameExists = await getUsernameExists(username);
        if (usernameExists) {
          setErrors(prev => ({
            ...prev,
            username: "Username is already taken"
          }));
          hasErrors = true;
        }
      }

      const emailExists = await getEmailExists(email);
      if (emailExists) {
        setErrors(prev => ({
          ...prev,
          email: "Email already taken"
        }));
        hasErrors = true;
      }

      // If no errors, proceed with signup
      if (!hasErrors) {
        setShowModal(true);
        handleSignUp(email, username, password);
      }
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };

  const closeModal = () => {
    navigate('/');
  };

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className="auth-page">
      <button onClick={handleBackClick} className="back-button">
        &larr; back
      </button>
      <div className="auth-content">
        <h3>sign up</h3>
        <p className="gray-subtitle">join <span style={{color: "white"}}>debate</span><span style={{color: "#4CAF50"}}>arena</span></p>
        <form onSubmit={handleSubmit} className="auth-form">
          <div className="input-container">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email"
              required
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
          </div>
          <UsernameInput 
            value={username}
            onChange={setUsername}
            error={errors.username}
          />
          <div className="input-container">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
              required
            />
          </div>
          <div className="input-container">
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="confirm password"
              required
            />
            {errors.password && <p className="error-message">{errors.password}</p>}
          </div>
          <p className="disclaimer">by signing up, you are agreeing to our privacy policy and terms of use</p>
          <button type="submit" className="auth-button">sign up</button>
        </form>
        <p className="auth-link">
          already have an account? <Link to="/login">login</Link>
        </p>
        <div className="separator">or</div>
        <button onClick={handleGoogleSignIn} className="google-auth-button">
          <img src={googleLogo} alt="Google Logo" className="google-logo" />
          sign in with Google
        </button>
      </div>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>email confirmation required</h2>
            <p>after you confirm your email, you should be able to login.</p>
            <button onClick={closeModal} className="auth-button">ok</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignupPage;