export const modes = {
    Standard: {
      prepTime: 30,
      debateTime: 100,
      rounds: [
        { name: "Round 1: Opening", description: "Standard Opening Round" },
        { name: "Round 2: Rebuttals", description: "Standard Rebuttal Round" },
        { name: "Round 3: Closing", description: "Standard Closing Round" },
      ],
    },
    Blitz: {
      globalTime: 420, 
      debateTime: 75, 
      rounds: []  
    },
    "Cross-Examine": {
      prepTime: 30,
      globalTime: 360,
      debateTime: 60,
      rounds: []
    },
    "BP": {
      prepTime: 900,
      debateTime: 420,
      rounds: [
        { name: "Round 1: Opening Government"},
        { name: "Round 2: Opening Oppposition"}
      ]
    }
  };