import React, { useEffect, useState } from "react";
import styles from "../styles/BPEvaluationReportCard.module.css";
import { generateDebateSummary, generateDebateBpSummary } from "../utils/api";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { getCurrentUserAndProfile } from "../queries/Queries";

const BPEvaluationReportCard = ({
  fromStoredDebate,
  topic,
  onClose,
  onHide,
  messages,
  userSide,
  summary,
  setSummary,
  mode
}) => {
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [winner, setWinner] = useState(null);
  const navigate = useNavigate();

  const handleNewDebate = () => {
    if(userAndProfile){
      navigate('/bpai')
      window.location.reload()
    }
    else{
      console.log("here and fromStoredDebate: " + fromStoredDebate)
      fromStoredDebate ? navigate('/login') : navigate('/ai')
    }
  }

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  useEffect(() => {
    if (!summary && messages.length !== 0 && userAndProfile) {
      generateSummary();
    }
  }, [userAndProfile]);

  // Parse winner when summary changes
  useEffect(() => {
    if (summary) {
      const winnerMatch = summary.match(/winner:\s*(opening \w+)/i);
      if (winnerMatch) {
        setWinner(winnerMatch[1]);
      }
    }
  }, [summary]);

  const generateSummary = async () => {
    const debateTranscript = messages
      .filter((msg) => msg.role !== "system")
      .map((msg) => `${msg.role.toUpperCase()}: ${msg.content.toLowerCase()}`)
      .join("\n\n");

    try {
      const generatedSummary = await generateDebateBpSummary(
        topic.toLowerCase(),
        debateTranscript,
        userSide.toLowerCase()
      );
      setSummary(generatedSummary.toLowerCase());
    } catch (error) {
      console.error("Failed to generate debate summary:", error);
      setSummary("failed to generate debate summary. please try again later.");
    }
  };

  return (
    <div className={styles.evaluationReportCard}>
      <div className={styles.header}>
        <button className={styles.button} onClick={onHide}>
          hide
        </button>
        <h3 className={styles.title}>
          {messages.length === 0 ? (
            <span>seems like nobody showed up!</span>
          ) : (
            <span>{winner || "analyzing debate..."}</span>
          )}
        </h3>
        <button className={styles.button} onClick={handleNewDebate}>
          new debate
        </button>
      </div>

      {messages.length > 0 && (
        <div className={styles.summaryContainer}>
          {userAndProfile ? (
            <ReactMarkdown
              components={{
                h3: ({ node, ...props }) => (
                  <h3 className={styles.sectionHeader} {...props} />
                ),
                p: ({ node, ...props }) => (
                  <p className={styles.paragraph} {...props} />
                ),
                ul: ({ node, ...props }) => (
                  <ul className={styles.list} {...props} />
                ),
                ol: ({ node, ...props }) => (
                  <ol className={styles.orderedList} {...props} />
                ),
                li: ({ node, ...props }) => (
                  <li className={styles.listItem} {...props} />
                ),
              }}
            >
              {summary}
            </ReactMarkdown>
          ) : (
            <div className={styles.signupPrompt}>
              <button
                onClick={() => navigate("/signup")}
                className={styles.startButton}
              >
                sign up to access
              </button>
              <p>
                already have an account?{" "}
                <span
                  onClick={() => navigate("/login")}
                  className={styles.loginLink}
                >
                  login
                </span>
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BPEvaluationReportCard;