import React, { useState } from 'react';
import { Search, MoreVertical, UserPlus, UserMinus, X } from 'lucide-react';

const FriendsTab = () => {
  const [activeSection, setActiveSection] = useState('friends');
  const [searchQuery, setSearchQuery] = useState('');

  // Dummy data - replace with actual data
  const friends = [
    { id: 1, name: "Sarah Chen", status: "Online", debates: 145 },
    { id: 2, name: "Michael Rodriguez", status: "Offline", debates: 89 },
  ];

  const friendRequests = [
    { id: 3, name: "Alex Thompson", status: "Online", debates: 67 },
    { id: 4, name: "Emma Wilson", status: "Online", debates: 234 },
  ];

  const discoveryUsers = [
    { id: 5, name: "James Smith", status: "Offline", debates: 178 },
    { id: 6, name: "Lucia Garcia", status: "Online", debates: 312 },
  ];

  const UserCard = ({ user, type }) => (
    <div className="flex items-center justify-between p-3 bg-gray-900/30 rounded-lg hover:bg-gray-900/40 transition-all">
      <div className="flex items-center gap-4">
        <div className="w-10 h-10 rounded-full overflow-hidden border border-gray-700">
          <img
            src="/api/placeholder/40/40"
            alt={user.name}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col">
          <span className="font-medium">{user.name}</span>
          <div className="flex items-center gap-2 text-sm">
            <span className="text-gray-400">{user.debates} debates</span>
            <span className="text-gray-400">•</span>
            <div className="flex items-center gap-1">
              <div className={`w-2 h-2 rounded-full ${user.status === 'Online' ? 'bg-green-500' : 'bg-gray-500'}`} />
              <span className="text-gray-400">{user.status}</span>
            </div>
          </div>
        </div>
      </div>
      
      <div className="flex items-center gap-2">
        {type === 'request' && (
          <>
            <button className="p-2 text-green-500 hover:bg-gray-800 rounded-lg transition-colors">
              <UserPlus size={18} />
            </button>
            <button className="p-2 text-red-500 hover:bg-gray-800 rounded-lg transition-colors">
              <X size={18} />
            </button>
          </>
        )}
        {type === 'friend' && (
          <button className="p-2 text-red-500 hover:bg-gray-800 rounded-lg transition-colors">
            <UserMinus size={18} />
          </button>
        )}
        {type === 'discovery' && (
          <button className="p-2 text-blue-500 hover:bg-gray-800 rounded-lg transition-colors">
            <UserPlus size={18} />
          </button>
        )}
        <button className="p-2 text-gray-400 hover:bg-gray-800 rounded-lg transition-colors">
          <MoreVertical size={18} />
        </button>
      </div>
    </div>
  );

  const TabButton = ({ active, onClick, children, count }) => (
    <button
      onClick={onClick}
      className={`px-4 py-2 rounded-lg font-medium transition-all flex items-center gap-2
        ${active ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-gray-200'}`}
    >
      {children}
      {count > 0 && (
        <span className="px-2 py-0.5 bg-gray-700 text-gray-300 text-xs rounded-full">
          {count}
        </span>
      )}
    </button>
  );

  return (
    <div className="space-y-6">
      {/* Search Bar */}
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <input
          type="text"
          placeholder="Search users..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full pl-10 pr-4 py-2 bg-gray-900 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-700"
        />
      </div>

      {/* Section Tabs */}
      <div className="flex gap-2">
        <TabButton 
          active={activeSection === 'friends'} 
          onClick={() => setActiveSection('friends')}
          count={friends.length}
        >
          Friends
        </TabButton>
        <TabButton 
          active={activeSection === 'requests'} 
          onClick={() => setActiveSection('requests')}
          count={friendRequests.length}
        >
          Requests
        </TabButton>
        <TabButton 
          active={activeSection === 'discover'} 
          onClick={() => setActiveSection('discover')}
        >
          Discover
        </TabButton>
      </div>

      {/* User Lists */}
      <div className="space-y-2">
        {activeSection === 'friends' && 
          friends.map(friend => (
            <UserCard key={friend.id} user={friend} type="friend" />
          ))
        }
        {activeSection === 'requests' && 
          friendRequests.map(request => (
            <UserCard key={request.id} user={request} type="request" />
          ))
        }
        {activeSection === 'discover' && 
          discoveryUsers.map(user => (
            <UserCard key={user.id} user={user} type="discovery" />
          ))
        }
        {((activeSection === 'friends' && friends.length === 0) ||
          (activeSection === 'requests' && friendRequests.length === 0) ||
          (activeSection === 'discover' && discoveryUsers.length === 0)) && (
          <div className="text-center py-8 text-gray-400">
            No users found
          </div>
        )}
      </div>
    </div>
  );
};

export default FriendsTab;