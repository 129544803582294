import React from 'react';
import styles from '../styles/FactCheckResult.module.css';

const FactCheckResult = ({ result }) => {
  const confidenceScore = parseFloat(result.confidenceScore);
  const meterPosition = result.isTrue ? confidenceScore : (100 - confidenceScore);

  const sortedSources = result.sources.sort((a, b) => Math.abs(b.score) - Math.abs(a.score));
  const trueSources = sortedSources.filter(source => source.score > 0);
  const falseSources = sortedSources.filter(source => source.score < 0);

  return (
    <div className={styles.resultCard}>
      <h2 className={styles.claim}>{result.claim.toLowerCase()}</h2>
      <div className={styles.meterContainer}>
        <div className={styles.meterLabels}>
          <span className={styles.falseLabel}>false</span>
          <span className={styles.trueLabel}>true</span>
        </div>
        <div className={styles.meter}>
          <div 
            className={styles.meterFill} 
            style={{width: `${meterPosition}%`}}
          ></div>
          <div 
            className={styles.meterMarker}
            style={{left: `${meterPosition}%`}}
          ></div>
        </div>
        <div className={styles.confidenceScore}>
          confidence: {confidenceScore.toFixed(2)}%
        </div>
      </div>
      {result.isBasicFact ? (
        <p className={styles.basicFactNote}>this is a widely known fact.</p>
      ) : (
        <div className={styles.sourcesContainer}>
          <div className={`${styles.sourcePanel} ${styles.falsePanel}`}>
            {falseSources.map((source, index) => (
              <SourceItem key={index} source={source} />
            ))}
          </div>
          <div className={`${styles.sourcePanel} ${styles.truePanel}`}>
            {trueSources.map((source, index) => (
              <SourceItem key={index} source={source} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const SourceItem = ({ source }) => {
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  return (
    <div className={styles.source}>
      <a href={source.url} target="_blank" rel="noopener noreferrer" className={styles.sourceTitle}>
        {truncateText(source.title.toLowerCase(), 58)}
      </a>
      <span className={styles.sourceOrigin}>{new URL(source.url).hostname.toLowerCase()}</span>
      <p className={styles.sourceSummary}>
        {truncateText(source.summary.toLowerCase(), 210)}
      </p>
    <span className={styles.sourceScore}>
      score: {Math.abs(source.score)} / 5
    </span>
  </div>
);
};

export default FactCheckResult;